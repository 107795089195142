<template>
    <div>
        <!-- Hero -->
        <v-img
        src="../assets/images/bgs/bg-3.svg"
        cover
        height="80vh"
        >
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="4">
                    <v-img contain max-width='90%'
                    src='../../public/images/pratka_logo.svg'
                    ></v-img>
                    <h1>От колет до палет на най-ниски цени!</h1>
                </v-col>
                <v-col cols="12" md="8">
                    <parcel-creation id="parcelCreation"></parcel-creation>
                </v-col>
            </v-row>
        </v-container>
            </v-img>
        <home-how-to></home-how-to>
        <home-hero></home-hero>
            <!-- Reviews -->
        <home-questions></home-questions>
        <!-- <home-contacts></home-contacts> -->
    </div>
</template>

<script>
// Relative Components
import HomeHero from '@/components/home/HomeHero'

import ParcelCreation from '@/components/ParcelCreation_large'

import HomeQuestions from '@/components/home/HomeFAQ'
// import HomeContacts from '@/components/home/HomeContacts'
import HomeHowTo from '@/components/home/HomeHowTo'


// Node Modules

export default {
name: 'CardBoxLandingPage',

components: {
    HomeHowTo,
    HomeHero,
    HomeQuestions,
    // HomeContacts,
    ParcelCreation,
},

data() {
    return {
    cookieLaw_text_bg: 'Ние използваме бисквитки на нашия уебсайт, за да ви предоставим най-подходящото изживяване, като запомним вашите предпочитания и повтаряме посещенията. С натискането на „Приемам“ вие се съгласявате с използването на ВСИЧКИ бисквитки.',
    cookieLaw_btn_bg: 'Приемам',

    // Footer
    footer_icons: [
    { name:"facebook", icon:"mdi-facebook", href:"https://www.facebook.com/www.Pratka.net"},
    { name:"instagram", icon:"mdi-instagram", href:"https://www.instagram.com/pratkabg_com/"},
    ],
    }
},

methods: {
},

mounted() {
},
};
</script>
<style scoped>
h1 {
font-size: 2.3rem;
}

a {
font-size: 1.1rem;
color: gray;
}

a:hover {
transition: ease-in 0.2s;
color: #D70002;
}

.iconHover:hover {
transition: ease-in 0.2s;
color: #D70002;
}

.toolbar {
background-color: antiquewhite;
color:aliceblue;
}

</style>