<template>
  <v-row class="primary--background" style="margin-bottom:10px;">
    <v-card class="flex" color="secondary" flat tile>
    <v-container>
      <v-row class=" align-center">
        <v-col cols="12" lg="5" class="col-lg-5 ">
          <v-img src="@/assets/images/landing/e-com.png" class=""
                 alt="Paquetería "></v-img>
        </v-col>
        <v-col cols="12" lg="7" class="col-lg-7 ">
          <span class="text-h4 font-weight-black  d-block pa-0 primary--text">{{ $vuetify.lang.t('$vuetify.home.ecommerce.title') }}</span>
          <span class="my-3 pa-0 text-body-1 font-weight--regular  text-black">
            {{ $vuetify.lang.t('$vuetify.home.ecommerce.subtitle') }}
          </span>
        
          <!-- <v-sheet class="pa-0 mt-5"> -->
            <br>
            <span class="ma-2 mb-2 d-flex align-start" v-for="item in ecommerceProArry" :key="item.index">
              <StartIcon class="d-block mt-1 mr-2"/>
              <span class="d-block">{{ $vuetify.lang.t(item.text) }}</span>
            </span>
          <!-- </v-sheet> -->

        </v-col>
      </v-row>
    </v-container>
  </v-card>
  </v-row>
</template>

<script>
import StartIcon from "@/components/home/new/StarIcon";

export default {
  name: 'EcommercePro',
  props: {
    msg: String
  },
  components: {
    StartIcon,
  },
  data() {
    return {
      ecommerceProArry: [
        {text: "$vuetify.home.ecommerce.body.bullet_1"},
        {text: "$vuetify.home.ecommerce.body.bullet_2"},
        {text: "$vuetify.home.ecommerce.body.bullet_3"},
        {text: "$vuetify.home.ecommerce.body.bullet_4"},
        {text: "$vuetify.home.ecommerce.body.bullet_5"},
        {text: "$vuetify.home.ecommerce.body.bullet_6"},
      ],
    }
  },
  computed: {
  },
  methods: {},
}
</script>

<style lang="scss" scoped>


</style>
