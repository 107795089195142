<template>
    <v-container grid-list-lg id='tracking'>
        <v-row no-gutters align="center" justify="center">    
            <v-col cols="12">

                <!-- <v-card elevation="4" color='secondary' class="pa-0">
                        <v-card-title class="pa-1">
                            <v-app-bar 
                            elevation="4"
                            color="primary"
                            dark
                            rounded
                            align='center' >
                            Проследяване на пратка
                            </v-app-bar>
                        </v-card-title>

                        <form
                            ref="form"
                            @submit.prevent=""
                            @submit="trackParcel"
                        >
                        <v-layout row justify-center>
                            <v-flex xs10 sm10 md5>
                                <v-text-field
                                required
                                background-color='white darken-3'
                                filled
                                label="Товарителница/Номер на пратка"
                                hint="Можете да въведете товарителницата на куриера или номерът на пратката предоставен от нас."
                                persistent-hint
                                v-model="parcelOrderNumber"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs10 sm10 md5>
                                <v-text-field
                                required
                                background-color='white darken-3'
                                filled
                                label="Телефон на подател"
                                hint="Телефонът въведен за пратката по време на нейното създаване."
                                persistent-hint
                                v-model="senderPhoneNumber"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row justify-center>
                            <v-flex xs8 sm8 md4>
                                <v-btn
                                rounded
                                block
                                color="primary"
                                style='margin-bottom:10px;'
                                type="submit"
                            >
                                Проследи</v-btn
                            >
                            </v-flex>
                        </v-layout>
                        </form>

                        <v-slide-y-transition>
                            <v-layout v-if='trackingAlert' row wrap justify-center>
                                <v-flex xs10 sm10 md10>
                                        <v-alert
                                        type="error"
                                        dismissible>
                                        {{alert_message}}
                                        </v-alert>
                                </v-flex>
                            </v-layout>
                        </v-slide-y-transition>

                        <v-layout v-if='parcelDataRecieved' row justify-center>
                            <v-flex xs11 sm11 md11>
                                <v-data-table
                                :headers="parcelHeaders"  
                                :items="parcelData"
                                item-key="orderNumber"
                                hide-default-footer
                                >
                                </v-data-table>
                            </v-flex>
                        </v-layout>

                        <v-layout v-if='showSupport' row align-center justify-center>
                            <v-flex xs10 sm10 md12>
                                <h2 class='text-md-center my-1'>За информация относно пратката</h2>
                            </v-flex>
                        </v-layout>

                        <v-layout v-if='showSupport' row align-center justify-center>
                            <v-flex xs11 sm11 md5>
                                <p class="text-md-h6 text-sm-h6 font-weight-black primary--text text-xs-center my-1">
                                        Европът-2000 АД
                                        </p>

                            </v-flex>
                            <v-flex xs11 sm11 md2>
                                <a href='tel:070010717' class="font-weight-black primary--text text-xs-center my-1">
                                0700 10 717
                                </a>
                            </v-flex>
                            <v-flex xs11 sm11 md3>
                                <a href='mailto:office@evropat.com' class="font-weight-black primary--text text-xs-center my-1">
                                    office@evropat.com     
                                </a>
                            </v-flex>
                        </v-layout>
                </v-card> -->
                
                <v-card elevation="4" color='secondary' class="pa-0">
                        <v-card-title class="pa-1">
                            <v-app-bar 
                            elevation="4"
                            color="primary"
                            dark
                            rounded
                            align='center' >
                            {{ $vuetify.lang.t("$vuetify.track_parcel.title")}}
                            </v-app-bar>
                        </v-card-title>

                        
                        <v-container>
                            <v-row align="center" justify="center">
                                <v-card-title > {{ $vuetify.lang.t("$vuetify.track_parcel.description")}} </v-card-title>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col cols="6" align="center" justify="center">
                                    <LazyYoutube
                                        src="https://youtu.be/OJ1-qeT63xo?si=i2ALisMANeEorElW"
                                    />
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col cols="6">
                                    <v-btn
                                    rounded
                                    block
                                    color="primary"
                                    style='margin-bottom:10px;'
                                    type="submit"
                                    to href="https://17track.net"
                                    >{{ $vuetify.lang.t("$vuetify.track_parcel.track")}}</v-btn>
                                </v-col>
                            </v-row>

                        </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import { LazyYoutube } from 'vue-lazytube'

export default {
    name: 'ParcelTracking',

    components: {
        LazyYoutube
    },

    
    data: () => ({
        trackingAlert: false,
        showSupport: false,
        alert_message: '',
        senderPhoneNumber: '',
        parcelOrderNumber: '',
        parcelDataRecieved: false,
        parcelData: [],
        parcelHeaders: [
          {
            text: 'Поръчка №:',
            align: 'start',
            filterable: false,
            value: 'orderNumber',
          },
          { text: 'Създадена на:', value: 'dateCreated' },
        //   { text: 'Parcel Type', value: 'parcelType' },
          { text: 'Тегло[кг]:', value: 'parcelDetails.shipmentWeight' },
        //   { text: 'Delivery Type', value: 'parcelDetails.deliveryType' },
        //   { text: 'От:', value: sender_address },
        //   { text: 'До:', value: recipient_address },
          { text: 'Обслужвана от:', value: 'transportedBy' },
          { text: 'Товарителница', value: 'transportOrderNumber' },
        //   { text: 'Payment Type', value: 'paymentType' },
          { text: 'Статут', value: 'parcelStatus' },
        ],
        sender_address: '',
        recipient_address: '',
    }),

    computed: {
    },

    methods: {

        async trackParcel() {
            let payload = {
                'order_number': this.parcelOrderNumber,
                'user_phone': this.senderPhoneNumber,
            }
            let trackingResponce = await axios.post('/parcel/track', payload)
            console.log(trackingResponce)
            if(trackingResponce.data.status == 'success'){
                this.parcelDataRecieved = true
                this.parcelData = trackingResponce.data.parcel
                this.showSupport = true
                let current_status = ((trackingResponce.data.evropatTrack.response).length) - 1
                this.parcelData[0].parcelStatus = trackingResponce.data.evropatTrack.response[current_status].stateName
                // console.log(this.parcelData)
            } else  {
                this.trackingAlert = true
                this.alert_message = 'В системата не е намера пратка с този номер или на този телефон.'
            }
        },

    },

    mounted() {
    },
};
</script>
