<template>
  <div>
    <parcel-tracking></parcel-tracking>
  </div>
</template>

<script>
import ParcelTracking from '@/components/ParcelTracking'

// Node Modules

  export default {
    name: 'TrackParcelPage',

    metaInfo: {
      title: 'Проследи пратка |'
    },

    components: {
      // HomeFooter,
      ParcelTracking,
    },

    computed: {
    },

    data: () => ({
    }),

    methods: {
    },

    mounted() {
    },
  }
</script>
<style scoped>
h1 {
  font-size: 2.3rem;
}
</style>
