/******************************************************************************
 *
 *                              PRATKABG CONFIDENTIAL
 *                              _____________________
 *
 * [2022] PRATKABG
 *  All Rights Reserved.
 *
 ******************************************************************************
 * This file, all
 * proprietary knowledge and algorithms it details are the sole property of
 * PRATKABG unless otherwise specified. The software this file
 * belong with is the confidential and proprietary information of PRATKABG.
 * ("Confidential Information"). You shall not disclose such
 * Confidential Information and shall use it only in accordance with the terms
 * of the license agreement you entered into with PRATKABG.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from PRATKABG.
 ******************************************************************************
 * @file    inrex.js
 * @author  Simeon Marlokov
 * @brief   Vue.js FE Router
******************************************************************************/

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// router setup
import routes from './routes'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth', }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: 'smooth', }
    }
  }
})

// NEW
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAdminAuth)) {
//     if (store.getters.isAdmin) {
//       next('/admin/');
//       return;
//     }
//   }
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters.isAuthenticated) {
//       next();
//       return;
//     }
//     // next();
//   }
//    else {
//     next();
//   }
// });

export default router


// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Login' && !store.getters.isAuthenticated) next({ name: 'Login' })
//   else next()
// })

// export default router
