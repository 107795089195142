<template>
    <v-container id='complete_order' align='center'>
        <v-card elevation="4" color='secondary'>
            <v-card-title class="pa-1">
                <v-app-bar 
                elevation="2"
                color="primary"
                dark
                rounded
                align='center' >
                {{ $vuetify.lang.t("$vuetify.cart.title")}}
                </v-app-bar>
            </v-card-title>

            <v-container>
                <!-- Content -->
                <v-row align='start' justify='center' class="pa-0">
                    <v-col cols='12' xs='12' sm='12' md='8' lg="8">
                        <v-container>
                            <v-row fluid>
                                <v-card width="100%" height="100%" color="white" elevation="2">
                                <v-stepper v-model="e6" vertical>
                                <v-stepper-step step="1">
                                    <v-card-title>{{ $vuetify.lang.t("$vuetify.cart.parcels")}}</v-card-title>
                                    <v-card-subtitle>{{ $vuetify.lang.t("$vuetify.cart.user_cart")}}</v-card-subtitle>
                                </v-stepper-step>

                                <v-stepper-content step="1">
                                    <v-row dense v-if="parcel_container[1]" class="pa-0 mb-2">
                                        <v-col cols="12" v-for="item in parcel_container[0]" :key="item.internalID">
                                            <v-card color="secondary" class="pa-0">
                                                <v-list-item v-if='item.service_provider=="direx"' two-line>
                                                    <v-list-item-content>
                                                        <div class="text-overline mb-4">
                                                            {{ $vuetify.lang.t("$vuetify.cart.partner")}} {{item.service_provider}}
                                                        </div>
                                                        <v-list-item-title class="text-h6 mb-1">
                                                        {{$vuetify.lang.t(reverse_typeOfService_international(item.serviceType))}} - {{$vuetify.lang.t(reverse_typeOfDelivery_international(item.deliveryType))}}
                                                        </v-list-item-title>
                                                        <v-list-item-title class="text-h6 mb-1">
                                                        {{item.shipmentDescription}}, {{ $vuetify.lang.t("$vuetify.cart.price")}} {{item.shipmentPrice}}{{ $vuetify.lang.t("$vuetify.cart.price_unit")}}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle v-if="item.deliveryType==2" class="primary--text text-subtitle-1 font-weight-bold mb-1">От: {{item.sender.senderOfficeName}}</v-list-item-subtitle>
                                                        <v-list-item-subtitle v-else class="primary--text text-subtitle-1 font-weight-bold mb-1">От: {{item.sender.senderProvince}} - {{item.sender.senderCity}} - {{item.sender.senderAddress}}</v-list-item-subtitle>
                                                        <v-list-item-subtitle class="primary--text text-subtitle-1 font-weight-bold mb-1">До: {{item.recipient.recipientCity}}, {{item.recipient.recipientAddress}} №{{item.recipient.recipientAddressNumber}}</v-list-item-subtitle>
                                                        <v-list-item-subtitle class="primary--text text-subtitle-1 font-weight-bold mb-1">Тегло: {{item.parcelWeight}}кг</v-list-item-subtitle>
                                                    </v-list-item-content>

                                                    <!-- Delete Item  -->
                                                    <v-list-item-avatar
                                                    tile
                                                    size="40"
                                                    >
                                                        <v-icon
                                                        small
                                                        color="primary"
                                                        @click="deleteItem(item)"
                                                        >
                                                            mdi-delete
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                </v-list-item>

                                                <v-list-item v-else three-line>
                                                    <v-list-item-content>
                                                        <div class="text-subtitle-2 mb-2">
                                                            {{ $vuetify.lang.t("$vuetify.cart.partner")}} {{getProvider(item.service_provider)}}
                                                        </div>
                                                        <v-list-item-title class="text-h6 mb-1">
                                                        {{item.shipmentDescription}} - {{$vuetify.lang.t(reverse_typeOfDelivery(item.deliveryType))}}, {{ $vuetify.lang.t("$vuetify.cart.price")}} {{item.shipmentPrice}}{{ $vuetify.lang.t("$vuetify.cart.price_unit")}}
                                                        </v-list-item-title>
                                                        <!-- {{ $vuetify.lang.t("$vuetify.cart.city_from")}} {{item.sender.senderCity}}  -->
                                                        <v-list-item-subtitle class="info--text font-weight-bold mb-1">{{ $vuetify.lang.t("$vuetify.cart.city_to")}} {{item.recipient.recipientCity}} | {{ $vuetify.lang.t("$vuetify.cart.weight")}} {{item.parcelWeight}}{{ $vuetify.lang.t("$vuetify.cart.weight_unit")}}</v-list-item-subtitle>
                                                        <!-- <v-container class="ma-0">
                                                            <v-row class="pa-0 ma-0">
                                                                <v-col class="pa-0 ma-0" cols="10">
                                                                    {{ $vuetify.lang.t("$vuetify.cart.insurance")}} -  {{ $vuetify.lang.t("$vuetify.cart.insurance_price")}}
                                                                </v-col>
                                                                <v-col class="pa-0 ma-0" cols="2">
                                                                </v-col>
                                                            </v-row>
                                                        </v-container> -->

                                                        <!-- <v-list-item-subtitle class="info--text font-weight-bold mb-1">{{ $vuetify.lang.t("$vuetify.cart.weight")}} {{item.parcelWeight}}{{ $vuetify.lang.t("$vuetify.cart.weight_unit")}}</v-list-item-subtitle> -->
                                                    </v-list-item-content>

                                                    <!-- Delete Item  -->
                                                    <v-list-item-avatar
                                                    tile
                                                    size="40"
                                                    >
                                                        <v-icon
                                                        small
                                                        color="primary"
                                                        @click="deleteItem(item)"
                                                        >mdi-delete
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                </v-list-item>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row dense v-else class="pa-0 mb-2">
                                        <v-col>
                                            <v-card color="secondary" align="center" justify="center">
                                            <v-card-subtitle>{{ $vuetify.lang.t("$vuetify.cart.empty")}}</v-card-subtitle>
                                            <v-card-subtitle>
                                                <v-hover v-slot="{ hover }">
                                                    <v-btn
                                                    :class="`elevation-${hover ? 12 : 2}`"
                                                    class="pa-3 transition-swing"
                                                    rounded
                                                    block
                                                    color="info"
                                                    to="/send">
                                                        <v-icon
                                                            left
                                                            dark
                                                        >
                                                            mdi-package-variant-closed-plus
                                                        </v-icon>
                                                        {{ $vuetify.lang.t('$vuetify.cart.create_new') }}
                                                    </v-btn>
                                                </v-hover>
                                            </v-card-subtitle>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                                </v-stepper>
                                </v-card>
                            </v-row>
                        </v-container>

                        <v-container v-if="parcel_container[1]" class="mt-2 fluid container--fluid" fluid>
                            <v-row fluid>
                                <v-card width="100%" height="100%" color="white" elevation="2">
                                <v-stepper v-model="e7" vertical>
                                    <v-stepper-step step="2">
                                        <v-card-title>{{ $vuetify.lang.t("$vuetify.cart.promo_code_title")}}
                                            <v-tooltip right color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                mdi-information
                                                </v-icon>
                                            </template>
                                            <span>{{ $vuetify.lang.t("$vuetify.cart.promo_code_description_cardbox")}}</span>
                                            </v-tooltip>
                                        </v-card-title>
                                        <v-card-subtitle>{{ $vuetify.lang.t("$vuetify.cart.promo_code_description")}}</v-card-subtitle>
                                        
                                    </v-stepper-step>

                                            <!-- <v-card width="100%" height="100%" color="red" elevation="4"> -->
                                    <v-stepper-content step="2">
                                        <v-row fluid>

                                            <v-col cols="12" xs='12' sm='12' md="4" class="text-subtitle-1 font-weight-bold">
                                                <v-text-field
                                                filled
                                                v-model="promoCode"
                                                background-color='white darken-3'
                                                dense
                                                :placeholder='$vuetify.lang.t("$vuetify.cart.promo_code_field")'>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4" xs='12' sm='12' md="8" class="">
                                                <v-btn
                                                rounded
                                                color="primary"
                                                @click="addPromoCode()"
                                                >
                                                Вземи отстъпка
                                                <v-icon
                                                dark>
                                                    mdi-package-variant-closed-plus
                                                </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                        <!-- <v-row> -->
                                            <v-expand-transition ease-in>
                                                <v-row v-if='alert_flag' align="center" justify="center">
                                                    <v-col cols="12">
                                                            <v-alert
                                                            :type="alert_type"
                                                            > {{ $vuetify.lang.t(alert_message) }}
                                                            </v-alert>
                                                    </v-col>
                                                </v-row>
                                            </v-expand-transition>
                                        <!-- </v-row> -->
                                    </v-stepper-content>
                                </v-stepper>
                                </v-card>
                            </v-row>
                        </v-container>
                    </v-col>

                    <!-- Right Support Panel -->
                    <v-col cols='12' xs='12' sm='12' md='4'>
                        <v-hover v-if="parcel_container[1]" v-slot="{ hover }">
                            <v-card
                            :class="`elevation-${hover ? 24 : 2}`"
                            class="mx-auto pa-2 mb-2 transition-swing"
                            elevation="4" outlined color='white' rounded="4">
                                <v-card-title>{{ $vuetify.lang.t("$vuetify.cart.order_summary")}}</v-card-title>
                                <v-card-subtitle>{{ $vuetify.lang.t("$vuetify.cart.order_summary_details")}}</v-card-subtitle>

                                <v-container>
                                    <v-row>
                                        <v-col class="text-h6">
                                            <v-icon small>mdi-package</v-icon> {{ $vuetify.lang.t("$vuetify.cart.total_price")}} {{total_price_order + $vuetify.lang.t('$vuetify.cart.price_unit')}}
                                        </v-col>
                                        
                                    </v-row>
                                    <v-row>
                                        <v-col class="primary--text text-h6">
                                            <v-icon color="primary" small>mdi-cart</v-icon> {{ $vuetify.lang.t("$vuetify.cart.final_price")}}  {{ final_price + $vuetify.lang.t('$vuetify.cart.price_unit') }}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="green--text text-h6">
                                            <v-icon color="green" small>mdi-sale</v-icon> {{ $vuetify.lang.t("$vuetify.cart.discount_price")}} {{ price_discount + $vuetify.lang.t('$vuetify.cart.price_unit') }}
                                        </v-col>
                                    </v-row>

                                </v-container>

                            </v-card>
                        </v-hover>
                        
                        <v-hover v-slot="{ hover }">
                            <v-card height="250"
                            :class="`elevation-${hover ? 24 : 2}`"
                            class="mx-auto pa-2 transition-swing"
                            elevation="4" outlined color='white'>
                                <v-card-title>{{ $vuetify.lang.t("$vuetify.support.title")}}</v-card-title>
                                <v-card-subtitle>{{ $vuetify.lang.t("$vuetify.support.subtitle")}}</v-card-subtitle>
                                <v-container>
                                    <v-row>
                                        <v-col>
                                            <v-icon large>mdi-phone</v-icon><a :href="$vuetify.lang.t('$vuetify.support.call_phone_request')">{{ $vuetify.lang.t("$vuetify.support.phone")}}</a>
                                        </v-col>
                                        
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-icon large>mdi-mail</v-icon><a :href="$vuetify.lang.t('$vuetify.support.email_request')">{{ $vuetify.lang.t("$vuetify.support.email")}}</a>
                                        </v-col>
                                    </v-row>
                                </v-container>

                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
                <!-- Invoice data -->
                <!-- <v-row>
                    <v-col cols="12"> -->
                        <v-row v-if="parcel_container[1]">
                            <v-col cols="12">
                                <v-stepper v-model="e8" vertical>
                                    <v-stepper-step step="3">
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6 mb-1">
                                                    {{ $vuetify.lang.t("$vuetify.cart.invoice_data.title")}} 
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="info--text font-weight-bold mb-1">
                                                    {{ $vuetify.lang.t("$vuetify.cart.invoice_data.description")}}
                                                    </v-list-item-subtitle>
                                            </v-list-item-content>

                                            <!-- Open Item  -->
                                            <v-list-item-avatar
                                            size="40"
                                            color="primary"
                                            >
                                                <v-icon
                                                color="white"
                                                @click="invoice_check_unfold ? e8=2 : e8=3"
                                                > {{invoice_check_unfold ? 'mdi-chevron-up' : 'mdi-chevron-down'}} 
                                                </v-icon>
                                            </v-list-item-avatar>
                                        </v-list-item>
                                    </v-stepper-step>

                                    <v-stepper-content step="3">
                                        <!-- <v-container> -->
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-text-field
                                                    v-model="invoice_data.company"
                                                    background-color='white darken-3'
                                                    color="primary"
                                                    outlined
                                                    :label="$vuetify.lang.t('$vuetify.cart.invoice_data.company')"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field
                                                    v-model="invoice_data.uin"
                                                    background-color='white darken-3'
                                                    color="primary"
                                                    outlined
                                                    :label="$vuetify.lang.t('$vuetify.cart.invoice_data.uin')"
                                                    hint='Ако сте регистрирани по ДДС напишете целия номер'
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field
                                                    v-model="invoice_data.city"
                                                    background-color='white darken-3'
                                                    color="primary"
                                                    outlined
                                                    :label="$vuetify.lang.t('$vuetify.cart.invoice_data.city')"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-text-field
                                                    v-model="invoice_data.address"
                                                    background-color='white darken-3'
                                                    color="primary"
                                                    outlined
                                                    :label="$vuetify.lang.t('$vuetify.cart.invoice_data.address')"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field
                                                    v-model="invoice_data.phone"
                                                    background-color='white darken-3'
                                                    color="primary"
                                                    outlined
                                                    :label="$vuetify.lang.t('$vuetify.cart.invoice_data.phone')"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field
                                                    v-model="invoice_data.contact"
                                                    background-color='white darken-3'
                                                    color="primary"
                                                    outlined
                                                    :label="$vuetify.lang.t('$vuetify.cart.invoice_data.contact')"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        <!-- </v-container> -->
                                    </v-stepper-content>
                                </v-stepper>
                            </v-col>
                        </v-row>
                    <!-- </v-col>
                </v-row> -->
                <!-- Main divider -->
                <v-divider style="margin:20px"></v-divider>

                <!-- Actions -->
                <v-row align='center' justify='center'>
                    <!-- New Parcel -->
                    <v-col cols="12" xs="12" sm="12" md="6" align='center' justify='center'>
                        <v-hover v-slot="{ hover }">
                            <v-btn
                            :class="`elevation-${hover ? 12 : 2}`"
                            class="pa-3 transition-swing"
                            rounded
                            block
                            color="info"
                            to="/send">
                                <v-icon
                                    left
                                    dark
                                >
                                    mdi-package-variant-closed-plus
                                </v-icon>
                                {{ $vuetify.lang.t('$vuetify.cart.create_new') }}
                            </v-btn>
                        </v-hover>
                    </v-col>
                    <!-- Send + Dialog -->
                    <v-col cols="12" xs="12" sm="12" md="6" align='center' justify='center'>
                        <v-dialog v-model="dialog" width="65%" transition="dialog-bottom-transition">
                            <template v-slot:activator="{attrs }">
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                    :loading="response_is_loading"
                                    :disabled="response_is_loading  || !parcel_container[1]"
                                    :class="`elevation-${hover ? 12 : 2}`"
                                    class="pa-3 transition-swing"
                                    rounded block
                                    color="primary"
                                    v-bind="attrs"
                                    @click="check_if_popup()">
                                    {{ $vuetify.lang.t('$vuetify.cart.send_btn') }}
                                    </v-btn>
                                </v-hover>
                            </template>
                            <v-card>
                                <!-- Title Bar -->
                                <v-toolbar dark color="primary">
                                    <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.cart.international_tos.title') }}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn
                                        icon dark
                                        @click="dialog = false">
                                        <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <!-- Content -->
                                <v-list three-line subheader>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold">
                                                1.{{ $vuetify.lang.t('$vuetify.prohibited_international_parcels.section_1.title') }}
                                            </v-list-item-title>
                                            <v-list-item-text v-for="item in textItemizer($vuetify.lang.t('$vuetify.prohibited_international_parcels.section_1.body'))" :key='item'>
                                                {{ item }}
                                            </v-list-item-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold">
                                                2.{{ $vuetify.lang.t('$vuetify.prohibited_international_parcels.section_2.title') }}
                                            </v-list-item-title>
                                            <v-list-item-text v-for="item in textItemizer($vuetify.lang.t('$vuetify.prohibited_international_parcels.section_2.body'))" :key='item'>
                                                {{ item }}
                                            </v-list-item-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold">
                                                3.{{ $vuetify.lang.t('$vuetify.prohibited_international_parcels.section_4.title') }}
                                            </v-list-item-title>
                                            <v-list-item-text v-for="item in textItemizer($vuetify.lang.t('$vuetify.prohibited_international_parcels.section_4.body'))" :key='item'>
                                                {{ item }}
                                            </v-list-item-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>

                                <v-form
                                ref="form_payment_finish"
                                v-model="valid_form_payment_finish"
                                @submit.prevent=""
                                @submit="checkout_shopping_cart()"
                                lazy-validation
                                autocomplete='off'>
                                    <v-checkbox
                                    required
                                    :rules="[v => !!v || $vuetify.lang.t('$vuetify.cart.tos_alert')]"
                                    color='primary'
                                    style="black--text"
                                    :disabled="response_is_loading  || !parcel_container[1]"
                                    :label="$vuetify.lang.t(tos)"
                                    hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                    color='primary'
                                    style="black--text"
                                    :disabled="response_is_loading  || !parcel_container[1]"
                                    v-model="consent_marketing_messages"
                                    :label="$vuetify.lang.t(tos_subscribe)"
                                    hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                    required
                                    :rules="[v => !!v || $vuetify.lang.t('$vuetify.cart.tos_alert')]"
                                    color='primary'
                                    style="black--text"
                                    :disabled="response_is_loading  || !parcel_container[1]"
                                    :label="$vuetify.lang.t(international_tos)"
                                    hide-details>
                                    </v-checkbox>
                                    <v-checkbox
                                    required
                                    :rules="[v => !!v || $vuetify.lang.t('$vuetify.cart.tos_alert')]"
                                    color='primary'
                                    style="black--text"
                                    :disabled="response_is_loading  || !parcel_container[1]"
                                    :label="$vuetify.lang.t(international_tos_customs)"
                                    hide-details>
                                    </v-checkbox>
                                </v-form>
                                <v-divider></v-divider>
                                <!-- Actions -->
                                <v-card-actions>
                                    <v-container>
                                        <v-row align="center" dense>
                                            <v-col>
                                                <v-btn
                                                color="secondary"
                                                block rounded class="black--text"
                                                @click="dialog = false"
                                                >
                                                {{ $vuetify.lang.t('$vuetify.cart.international_tos.disagree') }}
                                                </v-btn>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col>
                                                <v-btn
                                                color="primary"
                                                block rounded
                                                :loading="response_is_loading"
                                                :disabled="response_is_loading  || !parcel_container[1]"
                                                @click="checkout_shopping_cart()"
                                                >
                                                {{ $vuetify.lang.t('$vuetify.cart.international_tos.agree') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-dialog v-model="dialogDelete" max-width="50%">
          <v-card >
            <v-card-title class="text-h5 text-center">Сигурни ли сте, че искате да изтриете пратката?</v-card-title>
            <v-card-text>
            </v-card-text>
            <v-card-actions>
                <v-container>
                <v-row align="center" dense>
                    <v-col>
                        <v-btn block color="secondary" class="black--text" rounded @click="closeDelete">Не</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn block color="primary" rounded @click="deleteItemConfirm">Да</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="processing_payment">   
            <v-card>
                <v-card-title class="text-h5 primary white--text">
                Поръчкатa {{transaction_number}}  се обработва ...
                </v-card-title>

                <v-card-text align="center">
                    <v-progress-circular
                    style='margin: 10px;'
                    :size="120"
                    :width="7"
                    :value="payment_loader"
                    :color="payment_loader_color"
                    >
                    <v-img height='70px'
                                width='70px'
                                cover
                                src="../../assets/images/delivery_box.png" ></v-img></v-progress-circular>
                </v-card-text>

                <v-card-text align="center">
                В момента банката обработва Вашето плащане. Моля изчакайте.
                </v-card-text>

                <v-expand-transition>
                    <v-container>
                        <v-row v-if='show_payment_message' align='center' justify='center'>
                            <v-col cols="12">
                                <v-alert
                                :type="payment_message_type">
                                {{payment_message}}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-expand-transition>      

                <v-divider></v-divider>

                <v-card-text align="center" justify="center">
                    <v-img contain max-width='20%'
                    style="margin-top:10px"
                    src='../../assets/images/logo-grey.svg'
                    ></v-img>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
    name: 'HomeCart',


    data: () => ({
        // Stepper
        e6: 1,
        e7: 2,
        e8: 2,
        // 
        payment_loader: 0,
        payment_loader_color: 'primary',
        dialog: false,

        response_is_loading: false,
        processing_payment: false,  
        valid_form_payment_finish: true,
        show_payment_message: false,

        // Alert
        alert_flag: false,
        alert_type: '',
        alert_message: '',

        // Table/List Management
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {},
        promoCode: '',
        promo_used: null,
        promoCode_discount: null,

        // Transcard
        transcard_payment_encoded: '',
        transcard_payment_checksum: '',

        // Checkout Data
        senderPhone: '',
        parcelInvoice: '',
        transaction_number: '',
        pcode: '',
        invoice_data: {
            company: '',
            uin: '',
            city: '',
            address: '',
            phone: '',
            contact: '',
        },
        consent_marketing_messages: false,

        // Data
        tos: "$vuetify.cart.agree_tos",
        international_tos: "$vuetify.cart.agree_international_tos",
        international_tos_customs: "$vuetify.cart.agree_international_customs_tos",
        tos_subscribe: "$vuetify.cart.agree_subscribe",
        // Dummy Data
        currentParcels: [{
            "parcelInvoice":null,
            "parcelType":0,
            "parcelWeight":"0.5",
            "deliveryType":0,
            "shipmentPrice":4.05,
            "shipmentDescription":"Документи",
            'service_provider': "econt",
            "sender":{
                "senderDestID":"123",
                "senderOfficeID":"252",
                "senderAddress":"Ул. Георги Трингов 6",
                "senderName":"Симеон Марлоков",
                "senderPhone":"0897669022",
                "senderEmail":"simeon.marlokov@gmai.com",
                "senderOrganization":""
            },
            "recipient":{
                "recipientDestID":"273",
                "recipientOfficeID":"268",
                "recipientAddress":"Ул. Градинарска 7",
                "recipientName":"Ivan Ivanov",
                "recipientPhone":"0897113322",
                "recipientEmail":"",
                "recipientOrganization":""
            },
            "promoCode":""
        }],
    }),

    watch: {
        async processing_payment(newVal) {
            if(newVal == true){
                await this.checkPaymentStatus()
            }
        }
    },

    computed: {
        e7_checked()  {
            if (this.parcel_container[1]) return "2"
            else return "3"
        },

        invoice_check_unfold() {
            if (this.e8 == 2) return false
            else return true
        },

        total_price_order() {

            let total_price = 0.00
            this.parcel_container[0].forEach(element => {
                if(element.shipmentPrice){
                    total_price += parseFloat((element.shipmentPrice).toFixed(2))
                }
                
            });
            return total_price.toFixed(2)
            
        },

        price_discount() {
            let total_price = 0.00
            this.parcel_container[0].forEach(element => {
                if(element.shipmentPrice){
                    total_price += parseFloat((element.shipmentPrice).toFixed(2))
                }
                
            });
            let discount_price = 0.00;
            discount_price = total_price * (this.promoCode_discount / 100 )
            return discount_price.toFixed(2) 
        },

        final_price() {
            return (this.total_price_order - this.price_discount).toFixed(2)
        },

        parcel_container() {
            let content = (this.$store.getters.parcelsUser)
            let flag = false
            if (content.length != 0) {
                flag = true
            }
            return [content, flag]
            // return this.$store.getters.parcelsUser
        },

        transcard_url() {
            let phone_number = this.senderPhone
            phone_number = phone_number.trim()
            phone_number = phone_number.replace('+', '')
            phone_number = phone_number.replace(' ', '')
            return 'https://' + process.env.VUE_APP_PLATFORM_URL + '/checkout?parcel_invoice=' + this.parcelInvoice  + '&phone_number=' + phone_number + '&pcode=' + this.pcode
        },

        transcard_url_cancel() {
            let phone_number = this.senderPhone
            phone_number = phone_number.trim()
            phone_number = phone_number.replace('+', '')
            phone_number = phone_number.replace(' ', '')
            return 'https://' + process.env.VUE_APP_PLATFORM_URL + '/checkout?parcel_invoice=' + this.parcelInvoice  + '&phone_number=' + phone_number
        },
    },

    methods: {
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        textItemizer(string) {
            let array = string.split(";");
            return array;
        },
        getProvider(item) {
            let response_label = ''

            if(item == 'evropat') {
                response_label = 'Европът'
            }
            else if(item == 'econt') {
                response_label = 'Еконт'
            }
            else if(item == 'direx') {
                response_label = 'Директ-Пратка'
            }
            else if(item == 'fedex') {
                response_label = 'ФедЕкс'
            }
            else if(item == 'PratkaBG') {
                response_label = process.env.VUE_APP_SERVICE_AGENT
            }
            else{
                return item
            }
            return response_label
        },

        ...mapActions(['payParcels']),
        clearParcels() {
          this.payParcels();
        },

        reverse_typeOfDelivery(delivery_type) {
            let service_name = ''

            if(delivery_type == 1){
                service_name = "$vuetify.send_parcel.domestic.courier_selection.oto_order"
            }
            
            else if(delivery_type == 2){
                service_name = "$vuetify.send_parcel.domestic.courier_selection.ota_order"
            }

            else if(delivery_type == 3){
                service_name = "$vuetify.send_parcel.domestic.courier_selection.ato_order"
            }

            else if(delivery_type == 4){
                service_name = "$vuetify.send_parcel.domestic.courier_selection.ata_order"
            }
            return service_name
        },

        reverse_typeOfDelivery_international(delivery_type) {
            let service_name = ''
            
            if(delivery_type == 2){
                service_name = "$vuetify.send_parcel.domestic.courier_selection.ota_order"
            }

            else if(delivery_type == 4){
                service_name = "$vuetify.send_parcel.domestic.courier_selection.ata_order"
            }
            return service_name
        },

        reverse_typeOfService_international(service_type) {

            let service_name = ''
            
            if(service_type == 'LTR'){
                service_name = 'Икономична, без проследяване (до 2кг)'
            }

            else if(service_type == 'TRC'){
                service_name = 'Икономична, с проследяване (до 2кг)'
            }

            else if(service_type == 'PRM'){
                service_name = 'Колетна (до 20кг)'
            }

            else if(service_type == 'EXP'){
                service_name = 'Експресна Колетна (до 15кг)'
            }
            return service_name

        },

        reverse_typeOfParcel(parcel_type) {
            let service_name = ''

            if(parcel_type == 1){
                service_name = 'Документи'
            }
            
            else if(parcel_type == 2){
                service_name = 'Колет'
            }

            else if(parcel_type == 3){
                service_name = 'Адрес до офис'
            }

            else if(parcel_type == 4){
                service_name = 'Адрес до адрес'
            }
            return service_name
        },

        async get_evropat_offices(office_id) {
            let holder = ''
            let payload = await axios.get('/parcel/evropat/get-offices')
            let evropatOffices = payload.data
            evropatOffices.forEach(function (item) {
                
                if(item.officeID == office_id){
                    holder = item.officeName
                }
            });
            return holder
        },

        async reverse_office_evropat(office_id){
            let holder = await this.get_evropat_offices(office_id)
            return holder
        },

        // Operations
        deleteItem (item) {
            this.editedIndex = this.parcel_container[0].indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            this.closeDelete()
        },

        ...mapActions(['deleteParcel']),
        async closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.deleteParcel(this.editedIndex)
                this.editedItem = {}
                this.editedIndex = -1
            })
        },

        check_if_popup() {
            let check_if_domestic_only = true;
            let parcels = this.parcel_container[0];

            for (let i = 0; i < parcels.length; i++) {
                let destination = parcels[i].destination;
                // console.log(destination);
                // You can add any condition to check the destination value here
                if (destination === 'international') {
                    this.dialog = true;
                    check_if_domestic_only = false;
                    break;
                }
            }
            if (check_if_domestic_only) {
                this.checkout_shopping_cart(true);    
            }
        },

        // PAYMENT METHOD
        async checkout_shopping_cart(is_domestic = false) {
            this.response_is_loading = true
            let is_form_ready;
            try {
                is_form_ready = this.$refs.form_payment_finish.validate()
            } catch (e) {
                is_form_ready =false;
            }
            
            if (is_form_ready == true || is_domestic) {

                this.alert_flag = false
                this.alert_type = ''
                this.alert_message = ''
                // this.dialog = false

                Vue.analytics.fbq.event('trackCustom', {
                        content_name: 'Initiate Purchase',
                        value: this.total_price_order,
                        currency: 'BGN'
                })

                let payload = {
                    platform: process.env.VUE_APP_PLATFORM_URL,
                    flow: this.parcel_container[0][0].flow,
                    parcels: this.parcel_container[0],
                    invoice_data: this.invoice_data,
                    promo_used: this.promo_used,
                    consent_marketing: this.consent_marketing_messages
                }

                this.senderPhone = this.parcel_container[0][0].sender.senderPhone
                let request = await axios.post('/parcel/checkout', payload)
                if (request.data.status == 'error') {
                    this.error_message = request.data.errorMessage
                    this.parcelInvoice = request.data.tcf_data.invoice
                    this.$vuetify.goTo('#complete_order')
                } else {
                    this.parcelInvoice = request.data.tcf_data.invoice
                    this.pcode = request.data.pcode
                    
                    // if(process.env.VUE_APP_PLATFORM_URL == 'pratkabg.com') {
                    //     let url = request.data.tcf_data.payload.url
                    //     window.location.href = url;
                    // }
                    // else {
                    //     let url = request.data.tcf_data.payload.url
                    //     window.location.href = url;
                    // }

                    if (request.data.payment_system == true) {
                        // this.$gtag.event('purchase', { transaction_id: this.parcelInvoice, value: this.total_price_order, currency: 'bgn', items: payload.parcels})
                        this.$gtag.event('checkout', { transaction_id: this.parcelInvoice, value: this.total_price_order, currency: 'bgn', items: payload.parcels})
                        let url = request.data.tcf_data.payload.url
                        window.location.href = url;
                    } else {
                        // this.$gtag.event('purchase', { transaction_id: this.parcelInvoice, value: this.total_price_order, currency: 'bgn', items: payload.parcels})
                        this.$gtag.event('checkout', { transaction_id: this.parcelInvoice, value: this.total_price_order, currency: 'bgn', items: payload.parcels})
                        this.transcard_payment_encoded = request.data.tcf_data.payload.ENCODED
                        this.transcard_payment_checksum = request.data.tcf_data.payload.CHECKSUM
                        this.transaction_number = request.data.tcf_data.transaction_number
                        this.transcard_payment()
                    }
                }
            } else {
                this.alert_flag = true
                this.alert_type = 'error'
                this.alert_message = "$vuetify.cart.tos_alert"
                this.response_is_loading = false
                void(0)
            }

        },

        // Transcard Processing
        transcard_payment() {
            this.processing_payment = true;
            const form = document.createElement("form");
            const element1 = document.createElement("input"); 
            const element2 = document.createElement("input");
            const element3 = document.createElement("input");    
            const element4 = document.createElement("input");
            const element5 = document.createElement("input");    

            form.method = "POST";
            form.action = "https://www.transcard.bg/pay-gw/vpos";  
            
            element3.value='paylogin';
            element3.name="PAGE";
            form.appendChild(element3);

            element1.value=this.transcard_payment_encoded;
            element1.name="ENCODED";
            form.appendChild(element1);  

            element2.value=this.transcard_payment_checksum;
            element2.name="CHECKSUM";
            form.appendChild(element2);


            element4.value=this.transcard_url;
            element4.name="URL_OK";
            form.appendChild(element4);

            element5.value=this.transcard_url_cancel;
            element5.name="URL_CANCEL";
            form.appendChild(element5);

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        },

        async checkPaymentStatus(){
            this.payment_loader = 0
            let counter = 0
            let waiting_for_db_resposne = true
            let database_resp = {}
            let payload = {
                    'parcel_invoice': this.parcelInvoice,
                    'phone_number': this.senderPhone,
                    'pcode': this.pcode
                }
            
            while(waiting_for_db_resposne){
                this.payment_loader += 5
                if (counter >= 20) {
                    this.parcel_data = database_resp.data.parcel
                    this.loadingData = false
                    this.parcel_exists = true
                    this.payment_message_type = 'warning'
                    if(this.payment_status == 'Incomplete')
                        this.payment_message = 'Вашето плащане не е обработено. Моля, проверете дали плащането е реализирано и се свържете с нас за повече информация.'
                        this.show_payment_message = true
                        this.payment_successful = false
                    try{
                        this.tcf_data = database_resp.data.tcf_data
                    } catch (e) {
                        console.log(e)
                    }
                    return null
                }

                database_resp = await axios.post('/parcel/finished', payload)
                // console.log(database_resp)
                this.payment_status = database_resp.data.status

                if (this.payment_status == 'Incomplete' || this.payment_status == "awaitingPayment") {
                    counter += 1
                    await this.sleep(2500);
                }

                else if (this.payment_status == 'noData') {
                    waiting_for_db_resposne = false
                }

                else {
                    this.payment_status = database_resp.data.status
                    waiting_for_db_resposne = false
                }
            }
            this.payment_loader = 100

            if ( this.payment_status == "OK" || this.payment_status == "PAID" ){
                this.payment_loader_color = 'success'
                this.payment_message_type = 'success'
                this.payment_message = 'Поръчката е успешно регистрирана! Пренасочваме Ви към плащане...'
                await this.sleep(1500);
                this.show_payment_message = true
                this.payment_successful = true

            } else if (this.payment_status == 'Incomplete') {
                this.payment_loader_color = 'warning'
                this.payment_message_type = 'warning'
                this.payment_message = 'Пратката не е платена. Пренасочваме Ви към поръчката.'
                this.show_payment_message = true
                this.payment_successful = false

            } else if (this.payment_status == 'DENIED') {
                this.payment_loader_color = 'error'
                this.payment_message_type = 'error'
                this.payment_message = 'Плащането е неуспешно. Пренасочваме Ви към поръчката.'
                this.show_payment_message = true
                this.payment_successful = false
            }
            this.sleep(5000)
            this.clearParcels()
            // this.$router.push({ path: 'checkout', query: { parcel_invoice: database_resp.data.parcel.orderNumber, phone_number: database_resp.data.parcel.phoneNumber, pcode: this.pcode } })
        },

        async addPromoCode() {
            let payload = {
                platform: process.env.VUE_APP_PLATFORM_URL,
                code: this.promoCode,
            }

            // console.log(payload)
            let response = await axios.post('/parcel/promo', payload)
            // console.log(response)
            if (response.data.status == 'success') {
                this.promoCode_discount = response.data.data.discount
                this.promo_used = this.promoCode
                this.alert_flag = true
                this.alert_type = 'success'
                this.alert_message = "$vuetify.cart.promo_code_success"
            } else if (response.data.status == 'warning') {
                this.alert_flag = true
                this.alert_type = 'info'
                this.alert_message = "$vuetify.cart.promo_code_inactive"
            } else {
                this.alert_flag = true
                this.alert_type = 'info'
                this.alert_message = "$vuetify.cart.promo_code_failed"
            }
        }
    },

    // beforeRouteLeave (to, from, next) {

    // },

    created() {

    },

    mounted() {

    },

    beforeDestroy() {

    },
    
};
</script>

<style scoped>
</style>