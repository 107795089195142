<template>
    <v-container id='parcelCreation' >
        <v-row no-gutters align="center" justify="center">    
            <v-col cols="12" style="padding-top:50px;">

                <!-- Parcel Pick User Type -->
                <v-card elevation="4" align='center' color='secondary' class="pa-0">
                    <v-card-title>
                        <v-app-bar 
                        elevation="4"
                        color="primary"
                        dark
                        rounded
                        align='center' justify="center" class="title-text">
                        {{$vuetify.lang.t('$vuetify.send_parcel_cardbox.send_parcel_title')}}
                        </v-app-bar>
                    </v-card-title>

                    <v-container>
                        <v-row justify='center' class="pa-2">
                            <v-col 
                            cols="12" xs="12" sm="12" md="10" lg="10" class="d-flex">
                                <v-hover
                                    v-slot="{ hover }"
                                >
                                <v-card
                                :class="`elevation-${hover ? 24 : 3}`"
                                class="flex-grow-1 pa-2 transition-swing" color='secondary'>
                                <v-container class="mb-auto mt-auto pa-0" justify="center">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h5 mb-1 text-wrap text-center">
                                                {{$vuetify.lang.t('$vuetify.send_parcel_cardbox.send_parcel_subtitle')}}
                                            </v-list-item-title>
<!-- 
                                            <v-list-item-subtitle class="text-subtitle-1 mt-1 text-wrap text-center">
                                                {{$vuetify.lang.t('$vuetify.send_parcel_cardbox.send_description')}}
                                            </v-list-item-subtitle> -->
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-card-actions>
                                        <v-btn
                                            rounded
                                            color="primary"
                                            block
                                            link to="/send"
                                        >
                                        {{$vuetify.lang.t('$vuetify.send_parcel_cardbox.action')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-container>
                                </v-card>
                            </v-hover>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import Vue from 'vue'


export default {
    name: 'ParcelCreation_large',

    components: {
    },
    
    data: () => ({
    }),
    
    watch: {
    },

    computed: {
    },

    methods: {

    },

    mounted() {
    },
};
</script>

<style scoped>

    .alert-badge {
        position: fixed;
        z-index: 5;
        width: 70vw;
        bottom: 5vh;
    }

    .cardClass {
        border-radius: 15px;
        border: 5px solid red;
    }
    .centered-input >>> input {
      text-align: center
    }

    @media (min-width: 420px) and (max-width:768px){
        .tab-text {
            font-size: 14px;
            font-weight: bold;
        }
        .alert-badge {
            position: fixed;
            z-index: 5;
            width: 90vw;
            bottom: 1vh;
        }

    }
    @media (min-width: 280px) and (max-width: 420px){
        .tab-text {
            font-size: 12px;
            font-weight: bold;
        }

        .title-text {
            font-size: 4.5vw;
        }

        .alert-badge {
            position: fixed;
            z-index: 5;
            width: 90vw;
            bottom: 1vh;
        }
    }
</style>