<template>
    <div class="yellow lighten-4 ma-0 pa-0">
    <div class="shape ma-0 pa-0" data-negative="false">
      <svg fill="#ffffffA0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" class="ma-0 pa-0">
        <path class="shape-fill" opacity="0.33"
          d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z">
        </path>
        <path class="shape-fill" opacity="0.66"
          d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z">
        </path>
        <path class="shape-fill" opacity="0.1"
          d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z">
        </path>
      </svg>
    </div>
        <v-container id='faq' grid-list-lg>
            <v-layout column>
                <v-flex
                    class="text-md-h3 text-sm-h4 font-weight-black primary--text text-xs-center my-1"
                    >{{ $vuetify.lang.t('$vuetify.home.faq.title') }}</v-flex
                >
                <v-expansion-panels class="ma-2">
                    <v-expansion-panel
                    v-for="(item,i) in faqQuestions"
                    :key="i.question"
                    >
                    <v-expansion-panel-header class='text-md-h5 text-sm-h6 info--text'>
                        {{ $vuetify.lang.t(item.question) }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class='primary--text' style='margin-top:5px;margin-bottom:5px;'>
                        {{ $vuetify.lang.t(item.answer) }}
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'HomeFAQ',
    data() {
        return {
            faqQuestions: [
                { 
                    question: "$vuetify.home.faq.questions.q_1",
                    answer: "$vuetify.home.faq.questions.a_1"
                },
                { 
                    question: "$vuetify.home.faq.questions.q_2",
                    answer: "$vuetify.home.faq.questions.a_2"
                },
                { 
                    question: "$vuetify.home.faq.questions.q_3",
                    answer: "$vuetify.home.faq.questions.a_3"
                },
                { 
                    question: "$vuetify.home.faq.questions.q_4",
                    answer: "$vuetify.home.faq.questions.a_4"
                },
                { 
                    question: "$vuetify.home.faq.questions.q_5",
                    answer: "$vuetify.home.faq.questions.a_5"
                },
                { 
                    question: "$vuetify.home.faq.questions.q_6",
                    answer: "$vuetify.home.faq.questions.a_6"
                },
                { 
                    question: "$vuetify.home.faq.questions.q_7",
                    answer: "$vuetify.home.faq.questions.a_7"
                },
                { 
                    question: "$vuetify.home.faq.questions.q_8",
                    answer: "$vuetify.home.faq.questions.a_8"
                },
                { 
                    question: "$vuetify.home.faq.questions.q_9",
                    answer: "$vuetify.home.faq.questions.a_9"
                },
                { 
                    question: "$vuetify.home.faq.questions.q_10",
                    answer: "$vuetify.home.faq.questions.a_10"
                },
            ],
        };
    }
};
</script>

<style scoped>
/* .faq {
  background-color: transparent;
  background-image: linear-gradient(120deg, #fbd53d24 0%, #f9b00852 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fbd53d24),
    to(#f9b10800)
  );
  background-image: linear-gradient(#fbd53d24, #f9b10800);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding-top: 75px;
  padding-bottom: 75px;
} */
</style>