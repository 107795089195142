<template>
  <div>
      <home-tos></home-tos>
  </div>
</template>

<script>
// Relative Components
// import HomeFooter from '@/components/home/HomeFooter'
import HomeTos from '@/components/home/HomeToS'

// Node Modules

  export default {
    name: 'TOSPratka',
    components: {
      // HomeFooter,
      HomeTos,
    },

    computed: {
    },

    data: () => ({
    }),

    methods: {
    },

    mounted() {
    },
  }
</script>
<style scoped>

</style>
