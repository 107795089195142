<template>
  <div>
      <home-gdpr></home-gdpr>
      <!-- <home-footer></home-footer> -->
  </div>
</template>

<script>
// Relative Components
// import HomeFooter from '@/components/home/HomeFooter'
import HomeGdpr from '@/components/home/HomeGDPR'

// Node Modules

  export default {
    name: 'GDRPPratka',
    components: {
      // HomeFooter,
      HomeGdpr,
    },

    computed: {
    },

    data: () => ({
    }),

    methods: {
    },

    mounted() {
    },
  }
</script>
<style scoped>

</style>
