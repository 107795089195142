<template>
  <div>
      <home-checkout></home-checkout>
  </div>
</template>

<script>
// Relative Components
import HomeCheckout from '@/components/home/HomeCheckout'
// import HomeFooter from '@/components/home/HomeFooter'

// Node Modules

  export default {
    name: 'CheckoutView',

    components: {
      HomeCheckout,
      // HomeFooter
    },

    computed: {
    },

    data: () => ({
    }),

    methods: {
    },

    mounted() {
    },
  }
</script>
<style scoped>

</style>
