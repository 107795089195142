<template>
    <v-container grid-list-lg id='cookies' align='center' style='margin-bottom:15px;'>
        
        <v-layout col wrap>
    
            <v-flex xs12 sm12 md12>
                <!-- Parcel Start -->
                <v-card elevation="4" align='center' color='secondary'>
                    <v-card-title class='text-md-h4 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                        {{ $vuetify.lang.t("$vuetify.cookies.title") }}
                    </v-card-title>


                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.description") }}
                    </v-card-text>

                    <v-card-text v-for="item in cookie_types" :key="item.title" align='left' justify='center' class='font-weight-bold'>
                        <strong>{{ $vuetify.lang.t(item.title) }}</strong> {{ $vuetify.lang.t(item.answer) }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.confirming_agreenment") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='text-md-h4 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                    <strong>{{ $vuetify.lang.t("$vuetify.cookies.cookie_control") }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.cookie_control_p1") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.cookie_control_p2") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                    <strong>{{ $vuetify.lang.t("$vuetify.cookies.cookies_what") }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.cookies_what_answer") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                    <strong>{{ $vuetify.lang.t("$vuetify.cookies.cookies_about") }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.cookies_about_answer") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                    <strong>{{ $vuetify.lang.t("$vuetify.cookies.cookies_why") }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.cookies_why_answer") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        <ul>
                            <li v-for="item in cookies_why" :key="item">
                                {{ $vuetify.lang.t(item) }}
                            </li>
                        </ul>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='text-md-h4 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                    <strong>{{ $vuetify.lang.t("$vuetify.cookies.cookies_types") }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        <ol>
                            <li v-for="item in cookie_usage" :key="item.title">
                                <strong>{{ $vuetify.lang.t(item.title) }}</strong><p> {{ $vuetify.lang.t(item.answer) }} </p> 
                            </li>
                        </ol> 
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'> 
                    <strong>{{ $vuetify.lang.t("$vuetify.cookies.cookie_types_q1") }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.cookie_types_a1") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                    <strong>{{ $vuetify.lang.t("$vuetify.cookies.cookie_types_q2") }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.cookie_types_a2") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='text-md-h4 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                    <strong>{{ $vuetify.lang.t("$vuetify.cookies.cookie_tos_title") }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.cookie_tos_p1") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.cookie_tos_p2") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.cookies.cookie_tos_p3") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-regular'>
                        <ol>
                            <li v-for="item in cookie_tos" :key="item.info">
                                {{ $vuetify.lang.t(item.info) }}
                                <ul v-if="item.subbullets">
                                    <li v-for="bullet in item.subbullets" :key="bullet">
                                        {{ $vuetify.lang.t(bullet) }}
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </v-card-text>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'HomeCookies',
    data() {
        return {
            cookie_types: [{title: "$vuetify.cookies.cookie_cat_1_title", answer: "$vuetify.cookies.cookie_cat_1_description"}, 
            {title: "$vuetify.cookies.cookie_cat_2_title", answer: "$vuetify.cookies.cookie_cat_2_description"},
            {title: "$vuetify.cookies.cookie_cat_3_title", answer: "$vuetify.cookies.cookie_cat_3_description"}, 
            {title: "$vuetify.cookies.cookie_cat_4_title", answer: "$vuetify.cookies.cookie_cat_4_description"}],

            cookies_why: ["$vuetify.cookies.cookies_why_b1",
            "$vuetify.cookies.cookies_why_b2",
            "$vuetify.cookies.cookies_why_b3",
            "$vuetify.cookies.cookies_why_b4",
            "$vuetify.cookies.cookies_why_b5"],

            cookie_usage: [{title: "$vuetify.cookies.cookie_types_1_title", answer: "$vuetify.cookies.cookie_types_1_description"}, 
            {title: "$vuetify.cookies.cookie_types_2_title", answer: "$vuetify.cookies.cookie_types_2_description"},
            {title: "$vuetify.cookies.cookie_types_3_title", answer: "$vuetify.cookies.cookie_types_3_description"}, 
            {title: "$vuetify.cookies.cookie_types_4_title", answer: "$vuetify.cookies.cookie_types_4_description"}],

            cookie_tos: [{info: "$vuetify.cookies.cookie_tos_b1"}, 
            {info: "$vuetify.cookies.cookie_tos_b2"}, 
            {info: "$vuetify.cookies.cookie_tos_b3"}, 
            {info: "$vuetify.cookies.cookie_tos_b4", subbullets: ["$vuetify.cookies.cookie_tos_b4_sb1", 
                                                                  "$vuetify.cookies.cookie_tos_b4_sb2",
                                                                  "$vuetify.cookies.cookie_tos_b4_sb3",
                                                                  "$vuetify.cookies.cookie_tos_b4_sb4",
                                                                  "$vuetify.cookies.cookie_tos_b4_sb5",
                                                                  "$vuetify.cookies.cookie_tos_b4_sb6"]},
            {info:  "$vuetify.cookies.cookie_tos_b5"},
            {info:  "$vuetify.cookies.cookie_tos_b6"},
            {info:  "$vuetify.cookies.cookie_tos_b7"},
            {info:  "$vuetify.cookies.cookie_tos_b8"},
            {info:  "$vuetify.cookies.cookie_tos_b9"},
            {info:  "$vuetify.cookies.cookie_tos_b10"},
            {info:  "$vuetify.cookies.cookie_tos_b11"},
            {info:  "$vuetify.cookies.cookie_tos_b12"},
            {info:  "$vuetify.cookies.cookie_tos_b13"},
            {info:  "$vuetify.cookies.cookie_tos_b14"},
            {info:  "$vuetify.cookies.cookie_tos_b15"},
            {info:  "$vuetify.cookies.cookie_tos_b16"},
            {info:  "$vuetify.cookies.cookie_tos_b17"},
            {info:  "$vuetify.cookies.cookie_tos_b18"},
            {info:  "$vuetify.cookies.cookie_tos_b19"},
            {info:  "$vuetify.cookies.cookie_tos_b20"},
            {info:  "$vuetify.cookies.cookie_tos_b21"},
            {info:  "$vuetify.cookies.cookie_tos_b22"}]
        };
    },
    computed: {
    },
    methods: {
    }
};
</script>

<style scoped>
li {
    margin: 10px 0;
}
</style>