<template>
    <v-container grid-list-lg id='gdrp' align='center' style='margin-bottom:15px;'>
        
        <v-layout col wrap>
    
            <v-flex xs12 sm12 md12>
                <!-- Parcel Start -->
                <v-card elevation="4" align='center' color='secondary'>
                    <v-card-title class='text-md-h4 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                      {{ $vuetify.lang.t("$vuetify.gdpr.title") }}
                    </v-card-title>

                    <v-card-text v-for="item in section_list" :key="item" align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t(item) }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                    <strong>{{ $vuetify.lang.t("$vuetify.gdpr.data_usage_title") }}</strong> 
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.gdpr.data_usage_p1") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.gdpr.data_usage_p2") }}                    
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                    <strong>{{ $vuetify.lang.t("$vuetify.gdpr.service_bettering_title") }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.gdpr.service_bettering_p1") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                    <strong>{{ $vuetify.lang.t("$vuetify.gdpr.marketing_title") }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t("$vuetify.gdpr.marketing_p1") }}
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'> 
                    <strong>{{ $vuetify.lang.t("$vuetify.gdpr.analytics_title") }}</strong>
                    </v-card-text>

                    <v-card-text  v-for="item in analytics_list" :key="item" align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t(item) }}
                    </v-card-text>

                    <v-card-text align='center' justify='center' class='font-weight-bold'>
                    <strong>{{ $vuetify.lang.t("$vuetify.gdpr.gdpr_subject") }}</strong><br>
                    </v-card-text>
                    <v-card-text align='left' justify='center' class='font-weight-regular'>
                        <ol>
                            <li v-for="item in ordered_list" :key="item.title">
                            <strong>{{$vuetify.lang.t(item.title)}}</strong><br><p>
                            {{ $vuetify.lang.t(item.answer) }}
                            </p></li>
                        </ol>
                    </v-card-text>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'HomeGDPR',
    data() {
        return {
            section_list: ['$vuetify.gdpr.section_1', '$vuetify.gdpr.section_2', '$vuetify.gdpr.section_3', '$vuetify.gdpr.section_4', '$vuetify.gdpr.section_5', '$vuetify.gdpr.section_6', '$vuetify.gdpr.section_7', '$vuetify.gdpr.section_8', '$vuetify.gdpr.section_9', '$vuetify.gdpr.section_10', '$vuetify.gdpr.section_11', '$vuetify.gdpr.section_12', '$vuetify.gdpr.section_13', '$vuetify.gdpr.section_14'],
            ordered_list: [{title: "$vuetify.gdpr.b1_title", answer: "$vuetify.gdpr.b1_answer"},{title: "$vuetify.gdpr.b2_title", answer: "$vuetify.gdpr.b2_answer"},{title: "$vuetify.gdpr.b3_title", answer: "$vuetify.gdpr.b3_answer"},{title: "$vuetify.gdpr.b4_title", answer: "$vuetify.gdpr.b4_answer"},{title: "$vuetify.gdpr.b5_title", answer: "$vuetify.gdpr.b5_answer"},{title: "$vuetify.gdpr.b6_title", answer: "$vuetify.gdpr.b6_answer"},{title: "$vuetify.gdpr.b7_title", answer: "$vuetify.gdpr.b7_answer"},{title: "$vuetify.gdpr.b8_title", answer: "$vuetify.gdpr.b8_answer"},{title: "$vuetify.gdpr.b9_title", answer: "$vuetify.gdpr.b9_answer"}],
            analytics_list: ['$vuetify.gdpr.analytics_1', '$vuetify.gdpr.analytics_2', '$vuetify.gdpr.analytics_3', '$vuetify.gdpr.analytics_4', '$vuetify.gdpr.analytics_5', '$vuetify.gdpr.analytics_6', '$vuetify.gdpr.analytics_7', '$vuetify.gdpr.analytics_8', '$vuetify.gdpr.analytics_9', '$vuetify.gdpr.analytics_10', '$vuetify.gdpr.analytics_11' ,'$vuetify.gdpr.analytics_12']
        };
    },
    computed: {
    },
    methods: {
    }
};
</script>

<style scoped>
</style>