<template>
    <div>
        <home-payments></home-payments>
    </div>
</template>

<script>
// Relative Components
import HomePayments from '@/components/home/HomePayments'
// import HomeFooter from '@/components/home/HomeFooter'

// Node Modules

export default {
    name: 'PaymentView',

    components: {
        HomePayments,
    // HomeFooter
    },

    computed: {
    },

    data: () => ({
    }),

    methods: {
    },

    mounted() {
    },
}
</script>
<style scoped>

</style>
