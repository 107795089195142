<template>
  <div>
      <home-cookies></home-cookies>
      <!-- <home-footer></home-footer> -->
  </div>
</template>

<script>
// Relative Components
import HomeCookies from '@/components/home/HomeCookies'
// import HomeFooter from '@/components/home/HomeFooter'

// Node Modules

  export default {
    name: 'CookiesView',

    components: {
      HomeCookies,
      // HomeFooter
    },

    computed: {
      iconHover(value) {
        console.log(value)
        return 1
      }
    },

    data: () => ({
    }),

    methods: {
    },

    mounted() {
    },
  }
</script>
<style scoped>
h1 {
  font-size: 2.3rem;
}

a {
  font-size: 1.1rem;
  color: gray;
}

a:hover {
  transition: ease-in 0.2s;
  color: #D70002;
}

.iconHover:hover {
  transition: ease-in 0.2s;
  color: #D70002;
}

.toolbar {
  background-color: antiquewhite;
  color:aliceblue;
}

</style>
