<template>
  <span id='start'>
    <!-- <v-img gradient='90deg, rgba(251,213,61,1) 0%, rgba(251,180,61,1) 45%, rgba(250,158,0,1) 65%, rgba(215,0,2,1) 100%'> -->
      <v-app-bar 
      elevation="0"
      color="secondary"
      outlined
      align='center' >

      <v-app-bar-nav-icon 
        @click="drawer = true" 
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon>

      <v-toolbar-title to="/">
        <router-link to="/">
          <v-img contain max-width='95%'
          src='../../public/images/pratka_logo.svg'
          ></v-img>
        </router-link>
      </v-toolbar-title>

      <v-tabs align-with-title
      class='d-none d-sm-flex' 
      color='black'>
        <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="item in menu_options"
            :key="item.name"
            :to="item.href"
            class="mx-1 primary--text"
            icon
          >{{ $vuetify.lang.t(item.name) }}</v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <!-- Send From -->
      <v-menu style="margin-right:10px;" v-model="sendMenu" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on" text>
            {{ $vuetify.lang.t("$vuetify.navigation.menu.send_from.title") }}
            <v-icon small right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(lang, index) in locations" :key="index">
            <v-btn color="primary" :href="$vuetify.lang.t(lang.link)" text>
            <country-flag :country='lang.code' size='small'/>
            {{ $vuetify.lang.t(lang.title) }}
            <v-icon small right>mdi-menu-right</v-icon>
            </v-btn>
            <!-- <v-list-item-title>{{ $vuetify.lang.t(lang.title) }}<v-img contain max-height="15px" max-width="40px" src="@/assets/flags/bg.svg"></v-img></v-list-item-title> -->
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Language -->
      <v-menu style="margin-right:10px;" v-model="languageMenu" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" class="text-capitalize" v-bind="attrs" v-on="on" text>
            <v-icon left>mdi-translate</v-icon>
            {{ activeLang }}
            <v-icon small right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="(lang, index) in langs" :key="index" @click="handleMenuItemClick(lang)">
            <v-list-item-title><country-flag :country='lang.langcode' size='small'/>{{ lang.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn icon
      to="/cart">
        <v-badge
          color="primary"
          class="white--text"
          :content="parcel_shopping_count[0]"
          :value="parcel_shopping_count[1]"
        >
          <v-icon color="primary">mdi-cart</v-icon>
        </v-badge>
      </v-btn>
      <!-- <v-btn rounded color='d-none d-sm-flex primary secondary--text' href="https://platform.pratkabg.com"
      style="margin-left:10px;">
        {{login_btn_text}}
      </v-btn> -->
      <!-- <v-btn icon
      to="/login">
          <v-icon color="primary">mdi-account</v-icon>
      </v-btn> -->
      <!-- <v-btn rounded color='secondary primary--text' :to="{ name: 'Login'}">
        <v-icon>mdi-account</v-icon> {{register_btn_text}}
      </v-btn> -->
      
      </v-app-bar>
    <!-- </v-img> -->

    <!-- Mobile Navigation Drawer -->
    <v-navigation-drawer v-model="drawer" absolute temporary color='secondary'>
      <v-list nav dense>
        <!-- <v-list-item-group color="primary">
          <v-list-item href="https://platform.pratkabg.com">
            <v-list-item-title class="mobileMenu">
              Към платформа
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group> -->
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, index) in mobile_menu_options" :key="item.id"
          :to="item.href">
            <v-list-item-title class="mobileMenu"
            @click="tab = index">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

  </span>
</template>

<script>
import { mapActions } from 'vuex';
import CountryFlag from 'vue-country-flag'


export default {
    name: 'AppNavigation',
    components: {
        CountryFlag
    },
    data() {
        return {
          // Flags
          drawer: false,
          tab: null,

          // Buttons
          login_btn_text: 'Към платформа',
          register_btn_text: 'Регистрирай се',
          languageMenu: '',
          sendMenu: null,

          menu_options: [
            { name: "$vuetify.navigation.menu.options.home", href:"/", icon:"mdi-home"},
            { name: "$vuetify.navigation.menu.options.send", href:"/send", icon:"mdi-package"},
            { name: "$vuetify.navigation.menu.options.track", href:"/track", icon:"mdi-radar"},
            { name: "$vuetify.navigation.menu.options.about", href:"/#about", icon:"mdi-information"},
            { name: "$vuetify.navigation.menu.options.faq", href:"/#faq", icon:"mdi-frequently-asked-questions"},
            { name: "$vuetify.navigation.menu.options.contacts", href:"/#contacts", icon:"mdi-message-text-outline"},
          ],

          mobile_menu_options: [
              // { name:"Към платформа", href:"https://platform.pratkabg.com"},
              { name:"Изпрати пратка", href:"/send"},
              { name:"Проследи пратка", href:"/track"},
              // { name:"Цени", href:"/#pricing"},
              { name:"За нас", href:"/#about"},
              { name:"ЧЗВ", href:"/#faq"},
              { name:"Контакти", href:"/#contacts"},
          ],

          langs: [
            { title: 'Български', code: 'bg', short: "BG", langcode: "bg" },
            { title: 'English', code: 'en', short: "EN", langcode: "eu" },
          ],
          locations: [
            { title: '$vuetify.navigation.menu.send_from.bg.title', code:"bg", link: "$vuetify.navigation.menu.send_from.bg.link" },
            { title: '$vuetify.navigation.menu.send_from.ro.title', code:"ro", link: "$vuetify.navigation.menu.send_from.ro.link" },
            { title: '$vuetify.navigation.menu.send_from.ua.title', code:"ua", link: "$vuetify.navigation.menu.send_from.ua.link" },
          ],
        };
    },
    computed: {
//       locales() {
//   return this.$vuetify.lang.locales.map(local => {
//     return {name: this.$t('locales.' + local), val: local}
//   });
// },
      // login_btn_text() {
      //   return this.$store.state.language.locale[this.$store.getters.userLang.code].header_menu.platform
      // },
      // menu_options() {
      //   return [
      //         { name: this.$store.state.language.locale[this.$store.getters.userLang.code].header_menu.about_us, href:"/#about"},
      //         { name: this.$store.state.language.locale[this.$store.getters.userLang.code].header_menu.services, href:"/#services"},
      //         { name: this.$store.state.language.locale[this.$store.getters.userLang.code].header_menu.contact, href:"/#contacts"},
      //       ]
      // },
      // mobile_menu_options() {
      //   return [
      //         { name: this.$store.state.language.locale[this.$store.getters.userLang.code].header_menu.platform, href:"/login"},
      //         { name: this.$store.state.language.locale[this.$store.getters.userLang.code].header_menu.about_us, href:"/#about"},
      //         { name: this.$store.state.language.locale[this.$store.getters.userLang.code].header_menu.services, href:"/#services"},
      //         { name: this.$store.state.language.locale[this.$store.getters.userLang.code].header_menu.contact, href:"/#contacts"},
      //       ]
      // },
      parcel_shopping_count() {
        let count = (this.$store.getters.parcelsUser).length
        let flag = false
        // console.log(this.$store.getters.parcelsUser.length)
        // let test = this.$store.getters.parcelsUser
        // console.log(test)
        // console.log(test.length)
        if (count > 0 ) {
          flag = true
        }
        return [count, flag]
      },

      activeLang() {
        return (this.$store.getters.userLang).short
      },
    },
    methods: {
      ...mapActions(['changeLanguage']),
      async handleMenuItemClick (lang) {
        await this.changeLanguage(lang)
        console.log(this.$vuetify.lang)
        this.$vuetify.lang.current = lang.code
      },

      ...mapActions(['payParcels']),
        clearParcels() {
          this.payParcels();
        },
    },
    mounted() {
      // this.clearParcels()
    }
};
</script>

<style scoped>
a {
    color: white;
    text-decoration: none;
}

.mobileMenu {
  font-size: 18px;
  font-weight: 700;
  text-align: justify;
}

</style>