<template>
    <v-container id='hero'>
        <v-row cols="12" class="align-center justify-center">

            <v-col class="d-block d-sm-none" cols="12">
                <v-img contain max-width='90%'
                src='../../assets/images/Asset-5.jpg'
                ></v-img>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="8" lg="8">
                <v-row class="d-block d-sm-none align-center justify-center">
                    <v-col class='text-h5 font-weight-black primary--text'>
                        <!-- Най-доброто решение за доставки за вашият бизнес или лични нужди! -->
                        {{ $vuetify.lang.t('$vuetify.home.hero.title') }}
                    </v-col>
                </v-row>

                <v-row class="d-none d-sm-block align-center justify-left">
                    <v-col class='text-h5 font-weight-black primary--text'>
                        <!-- Най-доброто решение за доставки за вашият бизнес или лични нужди! -->
                        {{ $vuetify.lang.t('$vuetify.home.hero.title') }}
                    </v-col>
                </v-row>

                <!-- <v-row align-left justify-center>
                    <v-col class='text-h6 font-weight-bold black--text'>
                        {{ $vuetify.lang.t('$vuetify.home.hero.subtitle') }}
                    </v-col>
                </v-row> -->

                <v-row align-left justify-center>
                    <v-col class='text-subtitle'>
                        <!-- Цени в реално време, автоматизирано създаване на товарителници, интеграция за множество видове онлайн магазини с няколко клика! -->
                        {{ $vuetify.lang.t('$vuetify.home.hero.body') }}
                    </v-col>
                </v-row>

                <v-row class='hero-action'>
                    <v-col cols="12" sm="12" md="6">
                        <v-btn 
                        color='primary'
                        block
                        target="_blank"
                        x-large
                        :href="$vuetify.lang.t('$vuetify.home.hero.video')">
                            <!-- Как да ползвам Пратка.бг? -->
                            {{ $vuetify.lang.t('$vuetify.home.hero.btn_1') }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-btn 
                        color='primary'
                        block
                        target="_blank"
                        x-large
                        href="https://lgstcs.net/">
                            <!-- Консумативи - принтери и етикети -->
                            {{ $vuetify.lang.t('$vuetify.home.hero.btn_2') }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class='hero-action'>
                    <v-col cols="12" sm="12" md="6">
                        <v-btn 
                        color='primary'
                        block
                        target="_blank"
                        x-large
                        href="https://pratka.biz" >
                            {{ $vuetify.lang.t('$vuetify.home.hero.btn_3') }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-btn 
                        color='primary'
                        block
                        target="_blank"
                        x-large
                        href="https://youtu.be/qpFVkLded48" >
                            {{ $vuetify.lang.t('$vuetify.home.hero.btn_4') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>

            <v-col class="d-none d-sm-block" cols="4" xs="2" sm="4" md="4" lg="4">
                <v-img contain max-width='100%'
                src='../../assets/images/Asset-5.jpg'
                ></v-img>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'HomeHero',
    data() {
        return {
            selectedItem: 1,
            items: [
                { text: 'Ежемесечен Cashback от 15%', icon: 'mdi-checkbox-marked' },
                { text: 'Автоматично приготвяне на товарителници', icon: 'mdi-checkbox-marked' },
            ],
            parcel1_items: [
                { text: 'Палети/Колети', icon: 'mdi-checkbox-marked' },
                { text: 'Личен багаж', icon: 'mdi-checkbox-marked' },
            ],
            hero_carousel_images: [
                {
                    src: require('../../assets/images/partners/evropat.jpg'),
                },
                // {
                //    src: require('../../assets/images/partners/fedex.jpg'),
                // },
                {
                    src: require('../../assets/images/partners/hermes.jpg'),
                },
                {
                    src: require('../../assets/images/partners/econt.jpg'),
                },
                {
                    src: require('../../assets/images/partners/pricing.jpg'),
                },
            ],
        };
    },
};
</script>

<style scoped>
.home-hero {
    background-image: url('../../assets/images/bgs/bg-3.svg') fixed; 
    background-size: cover;
    width: 100%;
    height: 100%;
}

/* .hero-background{
    max-height:300px;
} */
.partner-carousel{
    height: 200px;
}


.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* max-width:200px; */
  max-height:200px;
}

/* .hero-container {
    margin-top: 10px;
    max-height: 50vh;
} */

.hero-text {
    /* font-family: 'Roboto'; */
    /* color: black; */
    font-style: bold;
    font-weight: 700;
    /* font-size: 34px; */
    line-height: 32px;
    /* display: flex; */
    /* align-items: center; */
    text-align: left;
    text-justify: newspaper;
}

.hero-action {
    margin-top:20px;
    /* font-family: 'Roboto'; */
    font-size: 32px;
    font-weight: 700;
    text-align: justify;
}
@media (min-width: 950px) {
    .hero-container {
        max-height: 50vh;
    }
    .partnersCarousel {
        width: 300px;
        height: 200px; 
    }
}
@media (min-width: 768px) and (max-width:950px){
    .hero-container {
        max-height: 45vh;
    }
    .hero-action {
        margin-top:20px;
        font-size: 22px;
        font-weight: 700;
        text-align: justify;
    }
    .partnersCarousel {
        width: 300px;
        height: 200px; 
    }
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width:300px;
        max-height:auto;
    }
}
@media (min-width: 420px) and (max-width:768px){
    .hero-container {
        max-height: 50vh;
    }
    .partnersCarousel {
        width: 300px;
        height: 200px; 
    }
    .hero-action {
        margin-top:20px;
        font-size: 22px;
        font-weight: 700;
        text-align: justify;
    }
    .partnersCarousel {
        width: 150px;
        height: 100px; 
    }
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width:200px;
        max-height:auto;
    }
}
@media (min-width: 280px) and (max-width: 420px){
    .hero-container {
        max-height: 60vh;
    }
    .partner-carousel{
        height: 100px;
    }
    .hero-action {
        margin-top:10px;
        font-size: 24px;
        font-weight: 700;
        text-align: justify;
    }
    .partnersCarousel {
        width: 150px;
        height: 100px; 
    }
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width:300px;
        max-height:auto;
        flex-wrap: wrap;
    }
}
</style>