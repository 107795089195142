<template>
    <v-main fluid id='contacts' style="height:100vh;">
        <v-img
        src="../../assets/images/bgs/bg-5.svg"
        cover
        >
            <v-container style='margin-bottom:25px;margin-top:25px;'>
                <v-row align='center' justify='center'>
                    <v-col style="margin-top:15px;" cols='12' xs='12' sm='12' md='6' lg='6'>
                        <v-hover v-slot="{ hover }">
                            <v-card
                            :class="`elevation-${hover ? 24 : 3}`"
                            class="mx-auto pa-6 transition-swing" outlined
                            align='center' color='secondary' height="100%"
                            >
                                <v-form
                                    ref="support_form"
                                    @submit.prevent=""
                                    @submit="sendEmail"
                                    lazy-validation
                                >

                                <v-row dense align='center' justify='center'>
                                    <v-col cols='12' xs='12' sm='12' md='12'>
                                        <v-text-field
                                        required
                                        :rules="support_validate_name_rules"
                                        v-model="contact_Name"
                                        background-color='white'
                                        color="primary"
                                        filled rounded
                                        dense
                                        :label="$vuetify.lang.t('$vuetify.home.contacts.form.name_placeholder')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols='12' xs='12' sm='12' md='7'>
                                        <v-text-field
                                        required
                                        :rules="support_validate_email_rules"
                                        v-model="contact_Email"
                                        background-color='white'
                                        color="primary"
                                        filled rounded
                                        dense
                                        :label="$vuetify.lang.t('$vuetify.home.contacts.form.email_placeholder')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols='12' xs='12' sm='12' md='5'>
                                        <v-text-field
                                        required
                                        :rules="support_validate_phone_rules"
                                        v-model="contact_Phone"
                                        background-color='white'
                                        color="primary"
                                        filled rounded
                                        dense
                                        :label="$vuetify.lang.t('$vuetify.home.contacts.form.phone_placeholder')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols='12' xs='12' sm='12' md='12'>
                                        <v-textarea
                                        required
                                        :rules="support_validate_message_rules"
                                        v-model="contact_Message"
                                        background-color='white'
                                        color="primary"
                                        filled rounded dense
                                        :label="$vuetify.lang.t('$vuetify.home.contacts.form.message_placeholder')"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>

                                <v-row dense align='center' justify='center'>
                                    <v-col cols='12' xs='12' sm='12' md='12'>
                                        <v-btn
                                        block
                                        rounded
                                        color="primary"
                                        type="submit"
                                        :disabled="completed_message"
                                        :loading="sending_message"
                                        >
                                        {{ $vuetify.lang.t('$vuetify.home.contacts.form.send') }}
                                        <template v-slot:loader>
                                            <span> {{ $vuetify.lang.t('$vuetify.home.contacts.form.request_process') }} <v-progress-circular indeterminate></v-progress-circular> </span>
                                        </template>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                </v-form>

                                <v-expand-transition ease-in>
                                    <v-row v-if='support_alert_flag' align='center' justify='center' style="margin-top:15px;">
                                        <v-col cols='12' xs='12' sm='12' md='12'>
                                            
                                                <v-alert
                                                :type="support_alert_type">
                                                {{ $vuetify.lang.t(support_alert_message) }}
                                                </v-alert>
                                            
                                        </v-col>
                                    </v-row>
                                </v-expand-transition>
                            </v-card>
                        </v-hover>

                    </v-col>

                    <v-col cols='12' xs='12' sm='12' md='6' lg="6">
                        
                            <span class="text-md-h4 text-sm-h4 font-weight-black primary--text text-xs-center">
                                {{ $vuetify.lang.t('$vuetify.home.contacts.title') }}
                            </span><br><br><span class="headline font-weight-black primary--text text-xs-center my-3 md-5">
                            <v-icon large>mdi-mail</v-icon><a :href="$vuetify.lang.t('$vuetify.home.contacts.emailto')">{{ $vuetify.lang.t("$vuetify.home.contacts.email") }}</a>
                            
                            <br></span><span class="headline font-weight-black primary--text text-xs-center my-3 md-5"><br>
                            <v-icon large>mdi-phone</v-icon><a :href="$vuetify.lang.t('$vuetify.home.contacts.phoneto')">{{ $vuetify.lang.t("$vuetify.home.contacts.phone") }}</a>
                            </span>
                        
                    </v-col>

                </v-row>
            </v-container>
        </v-img>
    </v-main>
</template>

<script>
import axios from 'axios';

export default {
    name: 'HomeContacts',
    data() {
        return {
            // Contact
            sending_message: false,
            completed_message: false,
            support_alert_flag: false,
            support_alert_type: "",
            support_alert_message: "",

            // Data
            contact_Name: '',
            contact_Email: '',
            contact_Phone: '',
            contact_Message: '',

            support_validate_name_rules: [
            v => !!v || 'Полето е задължително',
            v => !/([0-9])/.test(v) || 'Полето не може да съдържа цифри',
            ],
            support_validate_phone_rules:[
            v => !!v || 'Полето е задължително',
            v =>  v.length <= 15 || 'Номерът е твърде дълъг',
            v => /([0-9+()])/.test(v) || 'Полето може да съдържа само цифри'
            ],
            support_validate_email_rules:[
            v => !!v || 'Полето е задължително',
            v => /.+@.+\..+/.test(v) || 'Невалидна ел. поща',
            v => /([A-Za-z])\w/.test(v) || 'Пощата трябва да е на латиница'
            ],
            support_validate_message_rules: [
            v => !!v || 'Полето е задължително',
            ],
        };
    },
    computed: {
    },
    methods: {
        async sendEmail() {
            this.sending_message = true
            let is_form_ready = this.$refs.support_form.validate()
            
            if (is_form_ready == true) {
                let payload = {
                    platform: process.env.VUE_APP_PLATFORM_URL,
                    name: this.contact_Name,
                    email: this.contact_Email,
                    phone: this.contact_Phone,
                    type: 1,
                    message: this.contact_Message,
                }
                let response = await axios.post('/support', payload)

                if (response.data.status == "success") {
                    this.sending_message = false
                    this.completed_message = true
                    this.support_alert_flag = true
                    this.support_alert_type = "success"
                    this.support_alert_message = "$vuetify.home.contacts.form.msg_success"
                }
                else if (response.data.status == "warning") {
                    this.sending_message = false
                    this.completed_message = true
                    this.support_alert_flag = true
                    this.support_alert_type = "success"
                    this.support_alert_message = "$vuetify.home.contacts.form.msg_warning"
                }
                else {
                    this.sending_message = false
                    this.support_alert_flag = true
                    this.support_alert_type = "error"
                    this.support_alert_message = "$vuetify.home.contacts.form.msg_failed"
                }
            }
            else {
                this.sending_message = false
                this.support_alert_flag = true
                this.support_alert_type = "info"
                this.support_alert_message = "$vuetify.home.contacts.form.msg_incorrect"
                void(0)
            }
        }
    }
};
</script>

<style scoped>
</style>