// import axios from 'axios';

const state = {
  parcels: [],
  currentParcelContainer: {},
  parcel_error_flag: false,
  parcel_error_index: -1,
}

const getters = {
  parcelsUser: state => state.parcels,
  lastParcel: state => state.currentParcelContainer,
  errorFlag: state => state.parcel_error_flag,
  error_index: state => state.parcel_error_index,
}

const actions = {
  async proceedParcelDetails({commit}, parcel) {
    commit('setUserParcel', parcel)
  },
  async finishParcel({commit}, parcel) {
    commit('finishUserParcel', parcel)
  },
  async proceedParcelDetailsInternational({commit}, parcel) {
    commit('setUserParcel', parcel)
  },
  async international_finishParcel({commit}, parcel) {
    commit('finishUserInternationalParcel', parcel)
  },
  async payParcels({commit}) {
    commit('payUserParcels')
  },
  async deleteParcel ({commit}, parcel){
    commit('deleteUserParcel', parcel)
  },
}

const mutations = {
  setUserParcel(state, parcel) {
    state.currentParcelContainer = parcel;
  },
  finishUserParcel(state, parcel) {
    const holder = (state.parcels).concat(parcel)
    state.parcels = holder
    state.currentParcelContainer = null;
  },
  finishUserInternationalParcel(state, parcel) {
    const holder = (state.parcels).concat(parcel)
    state.parcels = holder
    state.currentParcelContainer = null;
  },
  payUserParcels(state) {
    state.parcels = [];
    state.currentParcelContainer = {};
  },
  deleteUserParcel(state, parcel) {
    const length = (state.parcels).length
    if(parcel == 0 && length == 1) {
      state.parcels = []
    }
    else {
      // state.parcels = (state.parcels).splice(parcel, 1)
      let valueToRemove = [state.parcels[parcel]];
      state.parcels = (state.parcels).filter(element => !valueToRemove.includes(element));
      // console.log("Array Elements After Removing Element At Index: " + parcel + " is " + state.parcels);
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}