<template>
    <v-container id='hero' class="pa-0 pt-1 pb-0">
        <v-row no-gutters align="center" justify="center" class='text-h4 font-weight-black primary--text'>
            <v-col cols="12"  align="center" justify="center">
                Как да създадем пратка в ПРАТКА.бг?
            </v-col>
        </v-row>
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12">
                <v-timeline
                :dense="$vuetify.breakpoint.smAndDown"
                >
                <v-timeline-item
                color="primary"
                    v-for="step in steps"
                    :key="step.id"
                >
                    <span slot="opposite">{{step.annotate}}</span>
                    <v-card color="secondary" class="elevation-2" width="100%">
                        <v-card-title class="text-h5 font-weight-black primary--text">
                            {{step.title}}
                        </v-card-title>
                        <v-card-text>
                            {{step.description}}
                        </v-card-text>
                    </v-card>
                </v-timeline-item>
                </v-timeline>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'HomeHowTo',
    data() {
        return {
            steps: [
                {
                    id: 1,
                    annotate: 'Стъпка 1',
                    title: 'Избери какво ще пратиш',
                    description: 'Въведи как изпращаш, типа пратка, тегло и адресите на подателя и получателя.'
                },
                {
                    id: 2,
                    annotate: 'Стъпка 2',
                    title: 'Получи оферта',
                    description: 'Натисни "Покажи цена" и нашите партньори ще ти дадат цена за твоята пратка!'
                },
                {
                    id: 3,
                    annotate: 'Стъпка 3',
                    title: 'Избери куриер и попълни',
                    description: 'Избери куриер и попълни необходимите данни за пратката - данни на подател и получател.'
                },
                {
                    id: 4,
                    annotate: 'Стъпка 4',
                    title: 'Каса',
                    description: 'Въведи своя код от Cardbox, започващ с *48* и получи -20% отстъпка на колетни пратки с Еконт!'
                },
                {
                    id: 5,
                    annotate: 'Стъпка 5',
                    title: 'Разпечатай',
                    description: 'След като платиш остани и разпечатай товарителницата си! Ще я получиш и по имейл.'
                },
                {
                    id: 6,
                    annotate: 'Стъпка 6',
                    title: 'Изпрати',
                    description: 'Отиди в най-близкия офис на Еконт и изпрати.'
                },
            ]
        };
    },
};
</script>

<style scoped>
</style>