import axios from 'axios';

import Vue from 'vue'
import VueMeta from 'vue-meta'
// import DisableAutocomplete from 'vue-disable-autocomplete'
// import VuePhoneNumberInput from 'vue-phone-number-input';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';


import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Google Analytics
// import VueAnalytics from 'vue-analytics'
import Pixel from 'vue-analytics-facebook-pixel'
// import VueFbCustomerChat from 'vue-fb-customer-chat'
import VueGtag from "vue-gtag";


import cookieconsent from 'vue-cookieconsent-component'
Vue.component('cookie-consent', cookieconsent)


// import './assets/scss/main.scss'
// export const serverURL = 'VUE_APP_SERVER_URL'

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL

let gTag = process.env.VUE_APP_SERVICE_GTAG
export const platform_url = process.env.PLATFORM_URL

Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(VueTelInput)
Vue.use(Pixel)
// Vue.use(VueFbCustomerChat, {
//   page_id: 119778901778640, //  change 'null' to your Facebook Page ID,
//   theme_color: '#333333', // theme color in HEX
//   locale: 'en_US', // default 'en_US'
// })

// Development - G-L2FTENP1W9
// Production - G-H5MWEZBJHC
// NEW - GTM-W4G2S32
// NEW NEW - G-K8LMY4H7JN
// Vue.use(VueAnalytics, {
//   id: 'G-K8LMY4H7JN',
//   router
// });

// STAGE - C18LM4P6YP
// LIVE - K8LMY4H7JN
Vue.use(VueGtag, {
  config: { id: gTag }
});

// OLD PRATKABG ONLY - 308702983861516
// NEW PRATKABG+PRATKA.CO - 1760303437636751
// ОЛД ПРАТКА - 410962917777700
// NEW NEW PRATKA - 347360044469843
// 06-03-2024 NEW - 840965887552184
Vue.analytics.fbq.init('840965887552184', {
  em: 'info@pratkabg.com'
})

// Vue.use(VuePhoneNumberInput);
// Vue.use(DisableAutocomplete);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

// NEW
axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      store.dispatch('logOut')
      return router.push('/')
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
