<template>
<span>
    <app-navigation></app-navigation>
        <v-fade-transition>
      <v-main fluid fill-height style="max-height: 21vh;">
        <v-img
      src="../assets/images/bgs/bg-2.svg"
      align='center'
      justify='center'
      max-height="90vh"
      >
        <v-layout justify-center align-center column>
            <div class="text-lg-h3 text-md-h3 text-sm-h4 font-weight-bold black--text text-xs-center"
            style='text-shadow: 10px 10px 25px white; margin-top:15px;'>
                {{message_404}}
            </div>
        </v-layout>
        </v-img>
    </v-main>
    </v-fade-transition>
</span>
</template>

<script>
import AppNavigation from '@/components/AppNavigation.vue'
export default {
    name: 'Page404',
    components: {
      AppNavigation,
    },
    data() {
        return {
            message_404: '404 - Страницата не е намерена!'
        };
    },
};
</script>

<style scoped>
.home-hero {
    background: url('../assets/images/bgs/bg-3.svg')  no-repeat center fixed; 
    background-size: cover;
    width: 100%;
    height: 100%;
}
</style>