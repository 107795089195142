// import axios from 'axios';

  const state = {
    lang: { title: 'Български', code: 'bg', short: "BG" }
  }
  
  const getters = {
    userLang: state => state.lang,
  }
  
  const actions = {
    async changeLanguage({commit}, lang) {
      commit('setUserLang', lang)
    },
  }
  
  const mutations = {
    setUserLang(state, lang) {
      state.lang = lang
    },
  }
  
  export default {
    state,
    getters,
    actions,
    mutations
  }