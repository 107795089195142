<template>
  <v-container class="packages-available" id='courierCompanies'>
    <v-row>
      <v-col class='hero-text text-h5 font-weight-black primary--text'>
        <!-- 70+ Куриерски фирми в една платформа -->
        {{ $vuetify.lang.t('$vuetify.home.couriers.title') }}
      </v-col>
    </v-row>
    <v-row class="d-none d-sm-flex" align-center justify-center>
      <v-col v-for="(item, index) in courierCompanies" :key="index" :class="{'active' : index === 0}" class="packages-available__country-item tab__item nav-item pa-2 py-1 ma-2 mx-1">
          <v-hover v-slot="{ hover }">
            <v-avatar :class="`elevation-${hover ? 24 : 4}`"
            class="mx-auto transition-swing">
              <v-img
              cover
              :src="item.img"
              ></v-img>
            </v-avatar>
          </v-hover>
      </v-col>
    </v-row>
    <v-row class="d-flex d-sm-none" align-center justify-center>
      <v-col v-for="(item, index) in courierCompanies" :key="index" :class="{'active' : index === 0}" class="packages-available__country-item tab__item nav-item pa-2 py-1 ma-6 mx-3">
          <v-hover v-slot="{ hover }">
            <v-avatar :class="`elevation-${hover ? 24 : 4}`"
            class="mx-auto transition-swing">
              <v-img
              cover
              :src="item.img"
              ></v-img>
            </v-avatar>
          </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>



export default {
  name: 'CourierCompanies',
  props: {
    msg: String
  },
  components: {
  },
  data() {
    return {
      courierCompanies: [
        {
          img: require(('@/assets/flags/bg.svg')),
        },
        {
          img: require(('@/assets/flags/at.svg')),
        },
        {
          img: require(('@/assets/flags/be.svg')),
        },
        {
          img: require(('@/assets/flags/hr.svg')),
        },
        {
          img: require(('@/assets/flags/cy.svg')),
        },
        {
          img: require(('@/assets/flags/cz.svg')),
        },
        {
          img: require(('@/assets/flags/dk.svg')),
        },
        {
          img: require(('@/assets/flags/ee.svg')),
        },
        {
          img: require(('@/assets/flags/fi.svg')),
        },
        {
          img: require(('@/assets/flags/fr.svg')),
        },
        {
          img: require(('@/assets/flags/de.svg')),
        },
        {
          img: require(('@/assets/flags/gr.svg')),
        },
        {
          img: require(('@/assets/flags/hu.svg')),
        },
        {
          img: require(('@/assets/flags/ie.svg')),
        },
        {
          img: require(('@/assets/flags/it.svg')),
        },
        {
          img: require(('@/assets/flags/lv.svg')),
        },
        {
          img: require(('@/assets/flags/lt.svg')),
        },
        {
          img: require(('@/assets/flags/lu.svg')),
        },
        {
          img: require(('@/assets/flags/mt.svg')),
        },
        {
          img: require(('@/assets/flags/nl.svg')),
        },
      ],

    }
  },
  methods: {

  },
}
</script>

<style scoped>
.hero-text {
    text-align: center;
    text-justify: newspaper;
}


</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.packages-available__country-link {
  min-width: 25px;
  border: none
}

.packages-available__country-link.active img,
.packages-available__country-link.icon-process-single:hover img,
.packages-available__country-link:hover img {
  transform: scale(1.5) perspective(1px)
}

.packages-available__country-item {
  min-width: 25px;
  border: none;
  cursor: pointer
}

.packages-available__country-item.active img,
.packages-available__country-item.icon-process-single:hover img,
.packages-available__country-item:hover img {
  transform: scale(1.5) perspective(1px)
}

.packages-available__country-image {
  height: 25px;
  width: 25px;
  background-color: transparent
}

</style>
