<template>
    <v-container id='heroTitle' class="pt-1 pb-0">
        <!-- <v-row no-gutters align="center" justify="center" class='text-h6 font-weight-bold primary--text'> -->
            <!-- <v-col class='text-h6 font-weight-bold primary--text'> -->
                <!-- Ползвайте от 25% до 70% отстъпка за вътрешни и международни пратки! -->
                <!-- {{ $vuetify.lang.t('$vuetify.home.send_from') }} -->
            <!-- </v-col> -->
        <!-- </v-row> -->
        <v-row no-gutters align="center" justify="center" class="ma-0 mt-1 pa-0">
            <v-col cols="4" sm="4" md="4" lg="4" align="center" justify="center"  class="ma-0 pa-0">
                <a href="https://www.colet1.net" target="_blank">
                <v-img class="d-none d-md-flex" contain max-width='50%'
                    src='../../../public/images/colet1_logo.svg'
                    ></v-img>
                </a>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" align="center" justify="center" class='font-weight-bold primary--text ma-0 pa-0"'>
                <!-- Ползвайте от 25% до 70% отстъпка за вътрешни и международни пратки! -->
                {{ $vuetify.lang.t('$vuetify.home.send_from') }}
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="4" align="center" justify="center" class="ma-0 pa-0">
                    <a href="https://www.posylka1.net" target="_blank">
                <v-img class="d-none d-md-flex" contain max-width='50%'
                    src='../../../public/images/posylka1_logo.svg'
                    ></v-img>
                </a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'HomeSites',
    data() {
        return {
        };
    },
};
</script>

<style scoped>
</style>