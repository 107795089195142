<template>
  <div>
    <home-hero-title></home-hero-title>
    <parcel-creation></parcel-creation>
  </div>
</template>

<script>
// Relative Components
import HomeHeroTitle from '@/components/home/HomeHeroTitle'
import ParcelCreation from '@/components/ParcelCreation'
// import HomeFooter from '@/components/home/HomeFooter'

// Node Modules

  export default {
    name: 'SendParcelView',

    metaInfo: {
      title: 'Изпрати пратка |'
    },

    components: {
      ParcelCreation,
      HomeHeroTitle
    },

    computed: {
      // iconHover(value) {
      //   console.log(value)
      //   return 1
      // }
    },

    data: () => ({
      // cookieLaw_text_bg: 'Ние използваме бисквитки на нашия уебсайт, за да ви предоставим най-подходящото изживяване, като запомним вашите предпочитания и повтаряме посещенията. С натискането на „Приемам“ вие се съгласявате с използването на ВСИЧКИ бисквитки.',
      // cookieLaw_btn_bg: 'Приемам',

      // // Footer
      // footer_icons: [
      // { name:"facebook", icon:"mdi-facebook", href:"https://www.facebook.com/www.Pratka.net"},
      // { name:"instagram", icon:"mdi-instagram", href:"https://www.instagram.com/pratkabg_com/"},
      // ],
    }),

    methods: {
    },

    mounted() {
    },
  }
</script>
<style scoped>
</style>
