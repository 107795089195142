<template>
    <transition class="d-block d-sm-none" name="fade-up-in" appear>
        <v-card
        v-if="shown"
        class="d-block d-sm-none pwa-prompt primary--text"
        :class="[{ shown }]"
        >
        <v-card-title> Инсталирай </v-card-title>
        <v-card-subtitle> Добави Пратка.БГ на своя телефон! </v-card-subtitle>

        <v-card-actions>
            <v-btn color="secondary" class="primary--text" @click="installPWA">
                Добави<v-icon right>mdi-bookmark-box</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="primary--text" @click="dismissPrompt">
                Затвори<v-icon right>mdi-close</v-icon>
            </v-btn>
        </v-card-actions>
        </v-card>
    </transition>
</template>


<script>
export default {
data: () => ({
    installEvent: undefined,
    shown: false,
}),

beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault()
    this.installEvent = e
    this.shown = true
    })
},

methods: {
    installPWA() {
    this.installEvent.prompt()
    this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the banner once the user's clicked
        if (choice.outcome === 'accepted') {
        // Do something additional if the user chose to install
        } else {
        // Do something additional if the user declined
        }
    })
    },

    dismissPrompt() {
    this.shown = false
    }
}
}
</script>


<style scoped lang="scss">
// If repurposing this component, you'll want to update the CSS variables.
.pwa-prompt {
position: fixed;
font-size: 1.0rem;
z-index: 20;
line-height: 1;
top: 3rem;
left:auto;
right: auto;
width: 80vw;
padding: 1rem;
display: flex;
align-items: center;
justify-content: center;
align-self: center;
border-radius: 6px;
border: 1px solid rgb(172, 172, 172);
// height: 4rem;
background: #FBD53D;
color: var(--background);
transform: translateY(0);
margin: 0;

.install-button {
    font-size: inherit;
    margin: 0 0 0 0.5rem;
    padding: 0.25em 0.5em;
    background-color: var(--bright);
    border: 0;
    border-radius: 4px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
}

.close-button {
    position: absolute;
    right: 1rem;
    top: -.0rem;
    font-size: 3rem;
    background: transparent;
    border: 0;
    padding: 0 0.75rem;
    height: 100%;
    line-height: 1;
}

// For the accessible text. Most projects have their own .sr-only class or similar in the base styles.
.sr {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -100vw;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
}

.fade-up-in-enter-active, .fade-up-in-leave-active {
transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1), transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
transform: translateY(0);
}

.fade-up-in-enter, .fade-up-in-leave-to {
opacity: 0;
transform: translateY(-4rem);
}
</style>