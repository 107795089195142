<template>
  <span >
    <v-img src="@/assets/images/landing/icons/icono.png" width="15" alt="Paquetería "></v-img>
  </span>
</template>

<script>


export default {
  name: 'StarIcon',
  props: {
    msg: String
  },
  components: {},
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>


</style>
