<template>
    <v-container grid-list-lg id='TOS' align='center' style='margin-bottom:15px;'>
        
        <v-layout col wrap>
    
            <v-flex xs12 sm12 md12>
                <!-- Parcel Start -->
                <v-card elevation="4" align='center' color='secondary'>
                    <v-card-title class='text-md-h4 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                        {{ $vuetify.lang.t('$vuetify.tos.title') }}
                    </v-card-title>


                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t('$vuetify.tos.owner') }}
                        <br> {{ $vuetify.lang.t('$vuetify.tos.info') }}
                    </v-card-text>

                    <div v-for="item in tos_data" :key="item.title">
                        <v-card-text align='left' justify='center' class='text-md-h5 text-sm-h6 font-weight-black'>
                            <strong>{{ $vuetify.lang.t(item.section) }}</strong>
                        </v-card-text>

                        <v-card-text align='left' justify='center' class='text-md-h6 text-sm-h6 font-weight-bold'>
                            <u>{{ $vuetify.lang.t(item.title) }}</u>
                        </v-card-text>

                        <v-card-text align='left' justify='center' class='font-weight-bold'>
                            <ol :start="item.start">
                                <li v-for="bullet in item.bullets" :key="bullet.info">
                                {{ $vuetify.lang.t(bullet.info) }}
                                <ul v-if="bullet.subbullets">
                                    <li v-for="subbullet in bullet.subbullets" :key="subbullet">
                                        {{ $vuetify.lang.t(subbullet.info) }}
                                        <ol v-if="subbullet.tertierybullets">
                                            <li v-for="tertierybullet in subbullet.tertierybullets" :key="tertierybullet">
                                                {{ $vuetify.lang.t(tertierybullet) }}
                                            </li>
                                        </ol>
                                    </li>
                                </ul>
                            </li>
                        </ol>
                        </v-card-text>
                    </div>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'HomeToS',
    data() {
        return {
            tos_data: [ 
                {section: "$vuetify.tos.section_1.title", start:1, title: "$vuetify.tos.section_1.subtitle", bullets:[ { info: "$vuetify.tos.section_1.b1", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_1.b2", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_1.b3", 
                                                                                                                            subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_1.b3sb1"},
                                                                                                                {info:"$vuetify.tos.section_1.b3sb2"},
                                                                                                                {info:"$vuetify.tos.section_1.b3sb3"}],
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_1.b4", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_1.b5", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_1.b6", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_1.b7"
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_1.b8", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_1.b9", 
                                                                                                                }
                                                                                                            ]
                },
                {section: "$vuetify.tos.section_2.title", start:1, title: "$vuetify.tos.section_2.subtitle", bullets:[ { info: "$vuetify.tos.section_2.b1", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_2.b2", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_2.b3",
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_2.b4",
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_2.b5", 
                                                                                                                },
                                                                                                            ]
                },
                {section: "$vuetify.tos.section_3.title", start:1, title: "$vuetify.tos.section_3.subtitle", bullets:[ { info: "$vuetify.tos.section_3.b1", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_3.b2", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_3.b3", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_3.b4", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_3.b5",
                                                                                                                subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_3.b5sb1"},
                                                                                                                {info:"$vuetify.tos.section_3.b5sb2"},
                                                                                                                {info:"$vuetify.tos.section_3.b5sb3"},
                                                                                                                {info:"$vuetify.tos.section_3.b5sb4"},
                                                                                                                {info:"$vuetify.tos.section_3.b5sb5"}]
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_3.b6", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_3.b7",
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_3.b8",
                                                                                                                subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_3.b8sb1"},
                                                                                                                {info:"$vuetify.tos.section_3.b8sb2"},
                                                                                                                {info:"$vuetify.tos.section_3.b8sb3"},]
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_3.b9", 
                                                                                                                }
                                                                                                            ]
                },
                {section: "$vuetify.tos.section_4.title", start:1, title: "$vuetify.tos.section_4.subtitle", bullets:[ { info: "$vuetify.tos.section_4.b1", 
                                                                                                                          subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_4.b1sb1"}]
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_4.b2", 
                                                                                                                          subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_4.b2sb1"},
                                                                                                                {info:"$vuetify.tos.section_4.b2sb2"},
                                                                                                                {info:"$vuetify.tos.section_4.b2sb3"},
                                                                                                                {info:"$vuetify.tos.section_4.b2sb4"},
                                                                                                                {info:"$vuetify.tos.section_4.b2sb5"}]
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_4.b3",
                                                                                                                subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_4.b3sb1"},
                                                                                                                {info:"$vuetify.tos.section_4.b3sb2"},
                                                                                                                {info:"$vuetify.tos.section_4.b3sb3"},]
                                                                                                                },
                                                                                                            ]
                },
                {section: "$vuetify.tos.section_5.title", start:1, title: "$vuetify.tos.section_5.subtitle", bullets:[ { info: "$vuetify.tos.section_5.b1", 
                                                                                                                          subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_5.b1sb1"},
                                                                                                                {info:"$vuetify.tos.section_5.b1sb2"}]
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_5.b2", 
                                                                                                                          subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_5.b2sb1"},
                                                                                                                {info:"$vuetify.tos.section_5.b2sb2"},
                                                                                                                {info:"$vuetify.tos.section_5.b2sb3"},
                                                                                                                {info:"$vuetify.tos.section_5.b2sb4"},
                                                                                                                {info:"$vuetify.tos.section_5.b2sb5"},
                                                                                                                {info:"$vuetify.tos.section_5.b2sb6"},
                                                                                                                {info:"$vuetify.tos.section_5.b2sb7"},
                                                                                                                {info:"$vuetify.tos.section_5.b2sb8"},
                                                                                                                {info:"$vuetify.tos.section_5.b2sb9"}]
                                                                                                                }
                                                                                                            ]
                },
                {section: "$vuetify.tos.section_6.title", start:1, title: "$vuetify.tos.section_6.subtitle", bullets:[ { info: "$vuetify.tos.section_6.b1", },
                                                                                                                { info: "$vuetify.tos.section_6.b2", },
                                                                                                                { info: "$vuetify.tos.section_6.b3", },
                                                                                                                { info: "$vuetify.tos.section_6.b4", },
                                                                                                                { info: "$vuetify.tos.section_6.b5", },
                                                                                                                { info: "$vuetify.tos.section_6.b6", },
                                                                                                                { info: "$vuetify.tos.section_6.b7", },
                                                                                                                { info: "$vuetify.tos.section_6.b8", },
                                                                                                                { info: "$vuetify.tos.section_6.b9", }
                                                                                                            ]
                },
                {section: "$vuetify.tos.section_7.title", start:1, title: "$vuetify.tos.section_7.subtitle", bullets:[ { info: "$vuetify.tos.section_7.b1", },
                                                                                                                { info: "$vuetify.tos.section_7.b2" },
                                                                                                                { info: "$vuetify.tos.section_7.b3" },
                                                                                                                { info: "$vuetify.tos.section_7.b4" },
                                                                                                                { info: "$vuetify.tos.section_7.b5" },
                                                                                                                { info: "$vuetify.tos.section_7.b6" },
                                                                                                            ]
                },
                {section: "$vuetify.tos.section_8.title", start:1, title: "$vuetify.tos.section_8.subtitle", bullets:[ { info: "$vuetify.tos.section_8.b1",
                subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_8.b1sb1"},
                                                                                                                {info:"$vuetify.tos.section_8.b1sb2"},
                                                                                                                {info:"$vuetify.tos.section_8.b1sb3"}]},
                                                                                                                { info: "$vuetify.tos.section_8.b2", subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_8.b2sb1"},
                                                                                                                {info:"$vuetify.tos.section_8.b2sb2"},
                                                                                                                {info:"$vuetify.tos.section_8.b2sb3"},
                                                                                                                {info:"$vuetify.tos.section_8.b2sb4"},
                                                                                                                {info:"$vuetify.tos.section_8.b2sb5"},
                                                                                                                {info:"$vuetify.tos.section_8.b2sb6"}]}
                                                                                                            ]
                },

                {section: "$vuetify.tos.section_9.title", start:1, title: "$vuetify.tos.section_9.subtitle", bullets:[ { info: "$vuetify.tos.section_9.b1", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_9.b2", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_9.b3", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_9.b4",
                                                                                                                subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_9.b4sb1"},
                                                                                                                {info:"$vuetify.tos.section_9.b4sb2"},
                                                                                                                {info:"$vuetify.tos.section_9.b4sb3"},
                                                                                                                {info:"$vuetify.tos.section_9.b4sb4"},
                                                                                                                {info:"$vuetify.tos.section_9.b4sb5"},
                                                                                                                ],
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_9.b5", 
                                                                                                                subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_9.b5sb1"},
                                                                                                                {info:"$vuetify.tos.section_9.b5sb2"},
                                                                                                                {info:"$vuetify.tos.section_9.b5sb3"},
                                                                                                                {info:"$vuetify.tos.section_9.b5sb4"},
                                                                                                                {info:"$vuetify.tos.section_9.b5sb5"},
                                                                                                                {info:"$vuetify.tos.section_9.b5sb6"},
                                                                                                                {info:"$vuetify.tos.section_9.b5sb7"}
                                                                                                                ],
                                                                                                                },
                                                                                                            ]
                },
                {section: "$vuetify.tos.section_10.title", start:1, title: "$vuetify.tos.section_10.subtitle", bullets:[ { info: "$vuetify.tos.section_10.b1",
                                                                                                            subbullets: [ 
                                                                                                                {info:"$vuetify.tos.section_10.b1sb1"},
                                                                                                                {info:"$vuetify.tos.section_10.b1sb2"},
                                                                                                                {info:"$vuetify.tos.section_10.b1sb3"},
                                                                                                                {info:"$vuetify.tos.section_10.b1sb4"},
                                                                                                                {info:"$vuetify.tos.section_10.b1sb5"},
                                                                                                                {info:"$vuetify.tos.section_10.b1sb6"}
                                                                                                                ],                                                     
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_10.b2", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_10.b3", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_10.b4", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_10.b5", 
                                                                                                                },
                                                                                                                { info: "$vuetify.tos.section_10.b6", 
                                                                                                                },
                                                                                                            ]
                },
                {section: "$vuetify.tos.section_11.title", start:1, title: "$vuetify.tos.section_11.subtitle", bullets:[ { info: "$vuetify.tos.section_11.b1", },
                                                                                                                { info: "$vuetify.tos.section_11.b2", },
                                                                                                                { info: "$vuetify.tos.section_11.b3", },
                                                                                                                { info: "$vuetify.tos.section_11.b4", },
                                                                                                                { info: "$vuetify.tos.section_11.b5", },
                                                                                                                { info: "$vuetify.tos.section_11.b6", },
                                                                                                                { info: "$vuetify.tos.section_11.b7", },
                                                                                                                { info: "$vuetify.tos.section_11.b8", },
                                                                                                                { info: "$vuetify.tos.section_11.b9", },
                                                                                                            ]
                },
                {section: "$vuetify.tos.section_12.title", start:1, title: "$vuetify.tos.section_12.subtitle", bullets:[ { info: "$vuetify.tos.section_12.b1", },
                                                                                                            ]
                }
            ]
        };
    },
    computed: {
    },
    methods: {
    }
};
</script>

<style scoped>
li {
    margin: 10px 0;
}
</style>