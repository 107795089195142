<template>
  <span id='start'>
      <v-app-bar 
      elevation="0"
      color="white"
      outlined
      align='center'>

      <v-app-bar-nav-icon
      color="primary"
      @click="drawer = true"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-toolbar-title to="/">
        <router-link to="/">
          <v-img contain max-width='90%'
          src='../../public/images/pratka_logo.svg'
          ></v-img>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Cart -->
      <v-btn icon color="primary"
      link to="/cart">
        <v-badge
          color="primary"
          class="white--text"
          :content="parcel_shopping_count[0]"
          :value="parcel_shopping_count[1]"
        >
          <v-icon color="primary">mdi-cart</v-icon>
        </v-badge>
      </v-btn>

      <!-- Platform -->
      <!-- <v-btn rounded color='d-none d-sm-flex primary secondary--text' href="https://platform.pratkabg.com"
      style="margin-left:10px;">
        {{ $vuetify.lang.t('$vuetify.navigation.login') }}
      </v-btn> -->
      
      </v-app-bar>

    <!-- Mobile Navigation Drawer -->
    <!-- <v-navigation-drawer v-model="drawer" app absolute temporary color='secondary'>
      <v-list nav dense>
        <v-list-item-group color="primary">
          <v-list-item href="https://platform.pratkabg.com">
            <v-list-item-title class="mobileMenu">
              Към платформа
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, index) in mobile_menu_options" :key="item.id"
          :to="item.href">
            <v-list-item-title class="mobileMenu"
            @click="tab = index">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->

    <v-navigation-drawer
      v-model="drawer"
      color='secondary'
      absolute
      temporary
    >
      <v-container class="ma-0 pa-0">
        <v-row no-gutters align="center"
        justify="center" class="ma-0 pa-2">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img src="../assets/images/delivery_box.png"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.menu.title') }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="drawer = false"><v-icon>mdi-close</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-row>

        <v-row no-gutters align="center"
        justify="left" class="ma-0 pa-0" style="display:block;">
          <v-list>
            <v-list-item
              v-for="item in menu_options"
              :key="item.title"
              color="primary"
              link :to="item.href"
              @click="drawer = false"
              class="ma-0 pa-2"
            >

              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $vuetify.lang.t(item.name) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
      </v-container>
    </v-navigation-drawer>

  </span>
</template>

<script>
import { mapActions } from 'vuex';


export default {
    name: 'AppNavigation',
    data() {
        return {
          // Flags
          drawer: false,
          tab: null,

          menu_options: [
            { name: "$vuetify.navigation.menu.options.home", href:"/", icon:"mdi-home"},
            { name: "$vuetify.navigation.menu.options.send", href:"/send", icon:"mdi-package"},
            { name: "$vuetify.navigation.menu.options.track", href:"/track", icon:"mdi-radar"},
            { name: "$vuetify.navigation.menu.options.about", href:"/#about", icon:"mdi-information"},
            { name: "$vuetify.navigation.menu.options.faq", href:"/#faq", icon:"mdi-frequently-asked-questions"},
            { name: "$vuetify.navigation.menu.options.contacts", href:"/#contacts", icon:"mdi-message-text-outline"},
          ],

          langs: [
            { title: 'Български', code: 'bg' },
            { title: 'English', code: 'en' },
          ],
        };
    },
    computed: {
      parcel_shopping_count() {
        let count = (this.$store.getters.parcelsUser).length
        let flag = false
        if (count > 0 ) {
          flag = true
        }
        return [count, flag]
      },

      activeLang() {
        return (this.$store.getters.userLang).title
      },
    },
    methods: {
      ...mapActions(['changeLanguage']),
      async handleMenuItemClick (lang) {
        await this.changeLanguage(lang);
      },

      ...mapActions(['payParcels']),
        clearParcels() {
          this.payParcels();
        },
    },
    mounted() {
      // this.clearParcels()
    }
};
</script>

<style scoped>
a {
    color: white;
    text-decoration: none;
}

.mobileMenu {
  font-size: 18px;
  font-weight: 700;
  text-align: justify;
}

</style>