<template>
    <v-container grid-list-lg id='cookies' align='center' style='margin-bottom:15px;'>
        
        <v-layout col wrap>
    
            <v-flex xs12 sm12 md12>
                <!-- Parcel Start -->
                <v-card elevation="4" align='center' color='secondary'>
                    <v-card-title class='text-md-h5 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                        {{ $vuetify.lang.t('$vuetify.payments.title') }}
                    </v-card-title>


                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t('$vuetify.payments.description') }}
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-text align='left' justify='center' class='text-md-h5 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                    <strong>{{ $vuetify.lang.t('$vuetify.payments.q_1') }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t('$vuetify.payments.a_1') }}
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-text align='left' justify='center' class='text-md-h5 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                    <strong>{{ $vuetify.lang.t('$vuetify.payments.q_2') }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t('$vuetify.payments.a_2') }}
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-text align='left' justify='center' class='text-md-h5 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                    <strong>{{ $vuetify.lang.t('$vuetify.payments.q_3') }}</strong>
                    </v-card-text>

                    <v-card-text align='left' justify='center' class='font-weight-bold'>
                        {{ $vuetify.lang.t('$vuetify.payments.a_3') }}
                    </v-card-text>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'HomeCookies',
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
    }
};
</script>

<style scoped>
li {
    margin: 10px 0;
}
</style>